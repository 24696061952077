import React, { useEffect, Suspense } from 'react';
//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//Hook
import useSignupHook from 'src/webapp/hooks/user/signup.hook';
//Comp
import _titleArea from '../../_titleArea';
import __step from './step';

export default function Step01() {
	//Hook
	const { checkedStorage //스토리지유무확인
		, validatedNameHandler //상호명유효성검사
		, onhandleOnKeyPress//엔터이벤트
		, pageOnLoadHandler //버튼클릭
		, validated //유효성확인객체useState
		, signupRef//회원가입Ref
	} = useSignupHook();
	//useEffect
	useEffect(() => {
		checkedStorage();//쿠키유무확인
		signupRef.current[0].focus();
	}, []);

	return (
		<>
			<section className={["container", styles.userContainer].join(" ")}>
				<div className={styles.wrapper}>
					{/* 타이틀 */}
					<Suspense fallback={null}>
						<_titleArea usePrev={true} title={`업체 등록하기`} subtitle={`상호명을 입력해주세요`} isMobile={true} />
					</Suspense>
					{/* 스텝 */}
					<Suspense fallback={null}><__step step01={true} idx={1} /></Suspense>

					{/* 입력필드 */}
					<div className={["flickPanel", styles.inputArea].join(" ")}>
						<div className={styles.inputField}><label>상호명</label>
							<div className={validated.key == null ? null : validated.key ? "invalidate" : "validate"}>
								<input onChange={(e) => validatedNameHandler(e)}
									onKeyDown={(e) => onhandleOnKeyPress(e, 6)}
									ref={el => (signupRef.current[0] = el)}
									type="text" inputMode="normal"
									placeholder="상호명을 입력해주세요" />
								<label className="validateMsg">{validated.msg}</label>
							</div>
						</div>
					</div>

					{/* 버튼 */}
					<div className={["fixedPanel", styles.btnArea].join(" ")}>
						<button onClick={() => pageOnLoadHandler(6)}
							disabled={validated.key == null ? true : validated.key ? true : false}
							className="submitButton">다음</button>
					</div>
				</div>
			</section>
		</>
	);
}