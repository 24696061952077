import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

//Store
import usePartnerStore from "src/webapp/stores/partner.store";
//Hook
import useComnHook from "src/webapp/hooks/comn/comn.hook";

const useFindHook = () => {

    //navigator
    const navigator = useNavigate();

    //Store
    const { setIdData, pwData, setPwData, setEmailFormMessage } = usePartnerStore();
    const { setStorage } = useComnHook();

    //ref 
    const findIdRef = useRef([]);
    const findPwRef = useRef([]);
    /* ===== 공통 ===== */
    const [ isLock, setLock ] = useState(false);

    //엔터작동핸들러
    const onEnterHandler = (e, type) => {//type 1.아이디찾기, 2.비밀번호찾기, 3.비밀번호변경
        if (e.key != 'Enter') { return; }
        onSubmitHandler(type);
    }
    //
    const onSubmitHandler = (type) => {//type 1.아이디찾기, 2.비밀번호찾기, 3.비밀번호변경
        switch(type) {
            case 1 : partnerFindIdAPI(); break;
            case 2 : partnerFindPwAPI(); break;
            case 3 : partnerUpdatePasswdAPI(); break;
        }
    }

    //폼입력핸들러
    const [ idForm, setIdForm ] = useState({ partnerName:"", email:"", msg:`회원정보에 등록한 이메일주소와 입력한 이메일주소가 같아야,\n인증번호를 받을 수 있습니다.` });//아이디입력State
    const [ pwForm, setPwForm ] = useState({ partnerId:"", email:"", msg:`회원정보에 등록한 이메일주소와 입력한 이메일주소가 같아야,\n인증번호를 받을 수 있습니다.` });//비밀번호입력
    const [ validated, setValidated ] = useState({ key:null, msg:'' });
    const onFormChangeHandler = (e, type) => {//type-1.아이디찾기, 2.비밀번호찾기
        const reg = /^[A-Za-z0-9]*@[A-Za-z0-9]([-.]?[A-Za-z0-9])*\.[A-Za-z0-9]{2,3}$/;
        const { value, name } = e.target;
        let kval = false;
        if(name == 'email') {
            if(!reg.test(value)) { kval = false; } 
            else { kval = true; }
        }
        if(validated.key != kval){
            setValidated({ key:kval, msg:'' });
        }
    }
    /* ===== 공통 ===== */

    /* ===== FindId ===== */
    //아이디찾기API
    const partnerFindIdAPI = async () => {
        if(!isLock) {
            setLock(true);
            try {
                const DATA = { "PARTNER_NAME": findIdRef.current[0].value, "EMAIL_ADDR": findIdRef.current[1].value }
                const response = await axios.post(`${process.env.REACT_APP_BACK_HOST}partnerFindIdForPartner.do`, DATA);
                const res = response.data;
                let msg = ''
                if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다");
                    msg = "입력하신 정보가 잘못되었습니다.\n다시한번확인후 시도해주세요.";
                }
                if (res.message == "NOT_FOUND_PARTNER") { console.log("존재하지 않는 업체 정보 입니다.다시한번확인후 시도해주세요.");
                    msg = "존재하지 않는 업체 정보 입니다.\n다시한번확인후 시도해주세요.";
                }
                if (res.message == "NOT_FOUND_EMAIL") { console.log("존재하지 않는 이메일 주소입니다.\n다시한번확인후 시도해주세요.");
                    msg = "존재하지 않는 이메일 주소입니다.\n다시한번확인후 시도해주세요.";
                }
                if (res.message == "NOT_MATCHES_EMAIL") { console.log("이메일 주소가 일치하지 않습니다.\n다시한번확인후 시도해주세요.");
                    msg = "이메일 주소가 일치하지 않습니다.\n다시한번확인후 시도해주세요.";
                }
                if (res.message == "SUCCESS") {
                    setStorage("findId", { "EMAIL_ADDR":findIdRef.current[1].value });
                    setIdData(res.data);
                    setEmailFormMessage("아이디 찾기를 위한");
                }
                setIdForm({...idForm, "msg":msg });
            } catch (error) { console.log("Error >>" + error); }
            setTimeout(function() { setLock(false); }, 3000);
        }
    }
    /* ===== FindId ===== */

    /* ===== Findpw ===== */
    //비밀번호찾기API
    const partnerFindPwAPI = async () => {
        if(!isLock) {
            setLock(true);
            try {
                const DATA = { "PARTNER_ID":findPwRef.current[0].value, "EMAIL_ADDR":findPwRef.current[1].value };
                const response = await axios.post(`${process.env.REACT_APP_BACK_HOST}partnerFindPasswdForPartner.do`, DATA);
                const res = response.data;
                let msg = ''
                if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다");
                    msg = "입력하신 정보가 잘못되었습니다.\n다시한번확인후 시도해주세요.";
                }
                if (res.message == "NOT_FOUND_PARTNER") { console.log("존재하지 않는 업체 정보 입니다.다시한번확인후 시도해주세요.");
                    msg = "존재하지 않는 업체 정보 입니다.\n다시한번확인후 시도해주세요.";
                }
                if (res.message == "NOT_FOUND_EMAIL") { console.log("존재하지 않는 이메일 주소입니다.\n다시한번확인후 시도해주세요.");
                    msg = "존재하지 않는 이메일 주소입니다.\n다시한번확인후 시도해주세요.";
                }
                if (res.message == "NOT_MATCHES_EMAIL") { console.log("이메일 주소가 일치하지 않습니다.\n다시한번확인후 시도해주세요.");
                    msg = "이메일 주소가 일치하지 않습니다.\n다시한번확인후 시도해주세요.";
                }
                if (res.message == "SUCCESS") {
                    setStorage("findPw", {"EMAIL_ADDR": findPwRef.current[1].value});
                    setPwData(res.data);
                    setEmailFormMessage("비밀번호 변경을 위한");
                }
                setPwForm({...idForm, "msg":msg });
            } catch (error) { console.log("Error >>" + error); }
            setTimeout(function() { setLock(false); }, 3000);
        }
    }

    /* ===== Findpw ===== */

    /* ===== Changepw ===== */
    //비밀번호유효성검사
    const onValidatedHandler = (e) => {//val - 1.비밀번호입력, 2.비밀번호확인입력
        const { name, value } = e.target;
        const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&-])[A-Za-z\d$@$!%*#?&-]{8,}$/g;
        
        let kval = true;
        let mval = '';
        if (name == "passwd") {
            if (!reg.test(value)) {
                kval = true; mval = '비밀번호가 형식에 맞지않습니다';
            } else {
                if (value != findPwRef.current[1].value) {
                    kval = true; mval = '비밀번호가 일치하지 않습니다';
                } else if (value == findPwRef.current[1].value) {
                    kval = false; mval = '사용가능한 비밀번호 입니다';
                }
            }
        }
        if (name == "passwdChk") {
            if (!reg.test(value)) {
                kval = true; mval = '비밀번호가 형식에 맞지않습니다';
            } else {
                if (value != findPwRef.current[0].value) {
                    kval = true; mval = '비밀번호가 일치하지 않습니다';
                } else if (value == findPwRef.current[0].value) {
                    kval = false; mval = '사용가능한 비밀번호 입니다';
                }
            }
        }
        if (validated.key != kval || validated.msg != mval){
            setValidated({ key: kval, msg: mval });
        }
    }

    //비밀번호 변경API
    const partnerUpdatePasswdAPI = async () => {
        if(!isLock) {
            setLock(true);
            try {
                const DATA = { "PARTNER_CD": pwData.PARTNER_CD, "PASS_WD": findPwRef.current[0] ? findPwRef.current[0].value : '', SUBMIT_TYPE: 1 };
                const response = await axios.post(`${process.env.REACT_APP_BACK_HOST}partnerMasterUpdateForPartner.do`, DATA);
                const res = response.data;
                if(res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                if(res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다"); }
                if(res.message == "SUCCESS") {
                    alert("비밀번호변경이 완료되었습니다.");
                    navigator('/');
                    return;
                } 
            } catch (error) { console.log("Error >" + error); }
            setTimeout(function() { setLock(false); }, 3000);
        }
    }
    /* ===== Changepw ===== */

    return { onEnterHandler, onSubmitHandler, onFormChangeHandler, validated//아이디및비밀번호 찾기 공통
        , partnerFindIdAPI, idForm, findIdRef//아이디찾기
        , partnerFindPwAPI, pwForm, findPwRef//비밀번호찾기
        , onValidatedHandler//비밀번호변경
    }

}

export default useFindHook;