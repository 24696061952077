import React, { forwardRef, useEffect, useState } from 'react';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//style
import styles from 'src/asset/scss/style/userSignup.module.scss';
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/select.scss';
//store
import useComnStore from 'src/webapp/stores/comn.store';

const Days = forwardRef((props, {dayStartBizRef, dayEndBizRef, breakTimeStartBizRef, breakTimeEndBizRef}) => {
    const { opt } = useComnStore();//store
    const { type, index, length, data } = props;//props
    /* =============== 영업일 타이틀 =============== */
    const dateStr = type === 2 ? (index === 0 ? '평일(월~금)' : length === 2 && index === 1 ? '주말' : length === 3 && index === 1 ? '토요일' : length === 3 && index === 2 ? '일요일' : null) : '영업시간';
    /* =============== 영업일 타이틀 =============== */
    /* =============== 휴게시간유무설정 =============== */
    const [ isBreakTime,setBreakTime ] = useState(false);
    /* =============== 휴게시간유무설정 =============== */
    console.log(data);
    useEffect(() => {
        //기존휴게시간데이터가 있으면 휴게시간 토글on
        if (data[index]?.START_BREAK_TIME > 10000){
            setBreakTime(true);
        }
    }, [])
	return (
        <>
        <div className={styles.dayArea}>
            <div className={styles.timeContainer}>
                <label>{dateStr}</label>
                <div className={styles.timeArea}>
                    <div className="select anchorBottom">
                        <SelectSearch ref={el => (dayStartBizRef.current[index] = el)}
                            onChange={(e) => {props.onValid(e, "start", index);(dayStartBizRef.current[index].value = e)}}
                            options={opt} value={opt.value}
                            defaultValue={data[index]?.START_BIZ_TIME !== 0 ? data[index]?.START_BIZ_TIME : opt[0].value}
                            placeholder='오픈 시간' />
                    </div>
                    <span>-</span>
                    <div className="select anchorBottom">
                        <SelectSearch ref={el => (dayEndBizRef.current[index] = el)}
                            onChange={(e) => {props.onValid(e, "end", index);(dayEndBizRef.current[index].value = e)}}
                            options={opt} value={opt.value}
                            defaultValue={data[index]?.END_BIZ_TIME !== 0 ? data[index]?.END_BIZ_TIME : opt[0].value}
                            placeholder='마감 시간' />
                    </div>
                </div>
            </div>
            {!isBreakTime ? <h2><label>휴게시간이 있나요?</label><button onClick={(e) => setBreakTime(!isBreakTime)}>설정하기<FontAwesomeIcon icon="fa-light fa-chevron-down" /></button></h2> : null}
            {isBreakTime ? 
                <div className={[styles.timeContainer, styles.breakTimeArea].join(" ")}>
                    <label>휴게시간</label>
                    <div className={styles.timeArea}>
                        <div className="select anchorBottom">
                            <SelectSearch ref={el => (breakTimeStartBizRef.current[index] = el)}
                                onChange={(e) => {(breakTimeStartBizRef.current[index].value = e)}}
                                options={opt} value={opt.value}
                                defaultValue={data[index]?.START_BREAK_TIME !== 0 ? data[index]?.START_BREAK_TIME : opt[0].value}
                                placeholder='오픈 시간' />
                        </div>
                        <span>-</span>
                        <div className="select anchorBottom">
                            <SelectSearch ref={el => (breakTimeEndBizRef.current[index] = el)}
                                onChange={(e) => {(breakTimeEndBizRef.current[index].value = e)}}
                                options={opt} value={data[index]?.END_BREAK_TIME !== 0 ? data[index]?.END_BREAK_TIME : opt[0].value}
                                defaultValue={opt[0].value}
                                placeholder='마감 시간' />
                        </div>
                        <button className={styles.btnBreakTime} onClick={(e) => setBreakTime(!isBreakTime)}><FontAwesomeIcon icon="fa-light fa-minus" /></button>
                    </div>
                </div>
            : null}
        </div>
        </>
	);
})
export default Days;