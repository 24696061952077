import React, { useEffect, Suspense, lazy, useCallback, useState } from 'react';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//observer
import { useInView } from 'react-intersection-observer';
//Store
import usePartnerStore from 'src/webapp/stores/partner.store';
//Hook
import useDatepickerHook from 'src/webapp/hooks/component/datepicker.hook';
import useSalesHook from 'src/webapp/hooks/sales/sales.hook';
//Comp
import NavBar from 'src/webapp/views/component/NavBar';
import TitleBar from 'src/webapp/views/component/TitleBar';
import Spinner from 'src/webapp/views/component/loading/Spinner';//로딩바
const _sales  = lazy(() => import('./comp/sales'), { ssr: false });//날짜선택
//bottomsheet
const _bsRangeDatepicker  = lazy(() => import('src/webapp/views/component/BsRangeDatepicker'), { ssr: false });//날짜선택

export default function Sales() {
    //Store
    const { isState } = usePartnerStore();
    //Hook
    const { initDateHandler
        , rangeIsOpen//날짜선택팝업useState
        , onRangeOpenHandler//날짜선택팝업on
        , handleRangePickerChange//날짜선택팝업off
        , rangeDate//선택된날짜useState 
    } = useDatepickerHook();//날짜팝업
    const { salesCntAPI//매출통계API
        , salesListAPI//매출리스트API
        , excelExportHandler//엑셀다운로드
        , page//infinity페이지useState
    } = useSalesHook();
    //Promise
    //데이터로딩
    const [ isPending, setPending ] = useState(true);
    const [ salesList, setSalesList ] = useState([]);//매출리스트
    const SalesListComp = () => { return !isPending ? <_sales list={salesList} /> : null; }
    useEffect(() => {
        setPending(true);
        if(isState && rangeDate != false) {
            Promise.all([ salesListAPI(0, rangeDate) ]).then((value) => {//매출통계API, 매출리스트API
                setSalesList(value[0]);
                setPending(false);
			});
        }
	}, [isState, rangeDate]);
    //infinity observer
    const [ ref, inView ] = useInView({ threshold: .5 });
    useEffect(() => {
        if(inView && (page > 20 || page != null)) { 
            Promise.all([ salesListAPI(1, rangeDate) ]).then((value) => {//매출리스트API
                setSalesList(salesList.concat(value[0]));
			});
        }
    }, [inView]);
    useEffect(() => {
        initDateHandler(-7, 0);//날짜초기셋팅(7일전~당일)
    }, []);

    return (
        <>
        <section className="fixedPanel container main">
            <NavBar id={2} />{/* 메뉴바 */}
            <div className="layer">
                <div className="fixedPanel">
                    <TitleBar title={`매출 관리`} />{/* 상단타이틀 */}
                    {/* 검색창 */}
                    <hr className="separate" />
                    <div className="searchArea">
                        <div className="dateArea">
                            <div className="datepicker">{(rangeDate.startDate ?? '') + ` - ` + (rangeDate.endDate ?? '')}</div>
                            <button onClick={(e) => onRangeOpenHandler()}
                                className="btnCalendar"><FontAwesomeIcon icon={['fal', 'calendar-lines']} /></button>
                            <button onClick={(e) => excelExportHandler(rangeDate)}
                                className="btnExcel" ><FontAwesomeIcon icon={['fal', 'arrow-down-to-line']} /></button>
                        </div>
                        <div>
                            <div className="cntArea">
                                <b>{salesList[0]?.TOT_CNT ?? 0}건</b><i/><em><span>총 매출 </span>{salesList[0]?.TOT_PRICE ?? 0}원</em>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flickPanel">
                    {isPending ? 
                        <div className="fullHeight"><Spinner isPending={true} /></div> 
                    : <>
                        <Suspense fallback={null}><SalesListComp /></Suspense>
                        {page == null ? null :  page > 0  && salesList.length > 0 ? <div ref={ref}><Spinner inView={true} /></div> : null}
                    </>}{/* 매출리스트 */}
                </div>
            </div>
        </section>

        {/* bottomSheet-datepicker */}
        {rangeIsOpen ? <Suspense fallback={null}><_bsRangeDatepicker isOpen={rangeIsOpen} onDismiss={handleRangePickerChange} /></Suspense> : null}
        </>
    );
}