import axios from "axios";
//Store
import useComnStore from 'src/webapp/stores/comn.store';
import usePartnerStore from 'src/webapp/stores/partner.store';
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import { useCallback, useState } from "react";
import useAxiosHook from "../comn/axios.hook";

const useDashBoardHook = () => {
    //Stores
    const { partner } = usePartnerStore();
    const { datepicker } = useComnStore();
    //Hook
    const { replaceDateHandler } = useComnHook();
    const { axiosPostHandler } = useAxiosHook();

    //등록된 취급차량갯수 API
    const vehicleCntAPI = useCallback(async () => {
        const DATA = { "PARTNER_CD": partner.COMN_CD };
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerVehicleCountForPartner.do`, DATA)
                .catch(err => { console.log("Error >", err); reject(err); })
                .then(response => {
                    const res = response.data;
                    //if(res.message == "SUCCESS" && res.data.CNT == 0) { changeOpenHandler("init"); }
                    resolve(res.message == "SUCCESS" ? res.data.CNT : 999)
                });
        });
    })

    /* ===== Dashboard ===== */
    //당일통계 API
    const [calcList, setCalcList] = useState({});
    const dashboardSisAPI = useCallback(async () => {
        const DATA = { "PARTNER_CD": partner.COMN_CD };
        return new Promise((resolve, reject) => {
            axiosPostHandler(`partnerDashboardSisForPartner.do`, DATA).then(response => {
                if (!response) { reject(response); }
                if (response.message == "SUCCESS") { setCalcList(response.data); resolve(response.data); }
            });
        });
    });

    //당일 및 익일 예약내역리스트 API
    const [todayReservList, setTodayReservList] = useState([]);
    const [tomorrowReservList, setTomorrowReservList] = useState([]);
    const reservDateListAPI = useCallback(async (type) => {//type 1.당일, 2.익일
        const DATA = { "PARTNER_CD": partner.COMN_CD, "TYPE": type };
        return new Promise((resolve, reject) => {
            axiosPostHandler(`partnerReservDateListForPartner.do`, DATA).then(response => {
                    if (!response) { reject(response); }
                    if (response.message == "SUCCESS" || response.message == "INVALID_DATA") {
                        resolve(response.data)
                        switch (type) {
                            case 1: setTodayReservList(response.data); break;
                            case 2: setTomorrowReservList(response.data); break;
                        }
                    }
                });
        });
    })

    //라인차트API
    const [lineChart, setLineChart] = useState([]);
    const lineChartAPI = useCallback(async (date) => {
        const startDate = replaceDateHandler(date.startDate);
        const endDate = replaceDateHandler(date.endDate);
        const DATA = { "PARTNER_CD": partner.COMN_CD, "START_DATE":startDate, "END_DATE":endDate };
        return new Promise((resolve, reject) => {
            axiosPostHandler(`partnerLineChartForPartner.do`, DATA).then(response => {
                console.log(response)
                    if (!response) { reject(response); }
                    if (response.message == "SUCCESS") { setLineChart(response.data); resolve(response.data); }
                    if (response.message != "SUCCESS") { resolve([]); }
                });
        });
    })

    //도넛차트API
    const [donutChart, setDonutChart] = useState([]);
    const donutChartAPI = useCallback(async (date) => {
        const startDate = replaceDateHandler(date.startDate);
        const endDate = replaceDateHandler(date.endDate);
        const DATA = { "PARTNER_CD": partner.COMN_CD, "START_DATE": startDate, "END_DATE":endDate };
        return new Promise((resolve, reject) => {
            axiosPostHandler(`partnerDonutChartForPartner.do`, DATA).then(response => {
                    if (!response) { reject(response); }
                    if (response.message == "SUCCESS") { setDonutChart(response.data); resolve(response.data); }
                    if (response.message != "SUCCESS") { resolve([]); }
                });
        });
    });
    /* ===== Dashboard ===== */

    return {
        vehicleCntAPI//취급차량등록확인
        , calcList, dashboardSisAPI//당일통계
        , lineChart, lineChartAPI//라인차트
        , donutChart, donutChartAPI//도넛차트
        , todayReservList, tomorrowReservList, reservDateListAPI//당일,익일예약리스트
    };
}

export default useDashBoardHook;