import React, { Suspense, useEffect, useState } from 'react';
//styles
import 'src/asset/scss/config/_styles.scss';
import './App.css';
//FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
//Hook
import useAppHook from 'src/webapp/hooks/comn/app.hook';
import useSocketHook from './webapp/hooks/comn/socket.hook';
//Store
import usePartnerStore from './webapp/stores/partner.store';
//pages-init
import Routers from 'src/webapp/views/comn/Route';
import Spinner from './webapp/views/component/loading/Spinner';

//pages/comn
function App() {
  //Hook
  const { resizeHandler//윈도우사이즈변경
  } = useAppHook();
  const { ws, socketConnect, reserveSuccessButtonTrigger, reserveUpdateButtonTrigger, reserveSuccessSound, reserveUpdateSound } = useSocketHook();
  const { partner, isState } = usePartnerStore();
  //useEffect
  useEffect(() => {
    resizeHandler();//윈도우사이즈변경
    //Fontawesome
    library.add(fat, fal, fas);
  }, []);
  const [ isLock, setLock ] = useState(false);
  useEffect(() => {
    if (isState && !isLock) {
      setLock(true);
      socketConnect();//소켓연결
      setTimeout(()=> {setLock(true);}, 3000);
    }
  }, [ws, partner, isState]);
  return (
    <>
      <Suspense fallback={<Spinner />}><Routers /></Suspense>
      <button ref={reserveSuccessButtonTrigger} style={{display:"none"}} onClick={() =>reserveSuccessSound.play()}></button>
      <button ref={reserveUpdateButtonTrigger} style={{display:"none"}} onClick={() =>reserveUpdateSound.play()}></button>
    </>
  );
}

export default App;