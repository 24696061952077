import React, { useState, useCallback, useRef } from 'react';
import axios from "axios";

//Store
import useComnStore from "src/webapp/stores/comn.store";
import useMembersStore from "src/webapp/stores/members.store";
import usePartnerStore from "src/webapp/stores/partner.store";
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';

const useMembersHook = () => {

    //Store
    const { partner } = usePartnerStore();
    const { bottomsheet, setBottomsheet, datepicker } = useComnStore();
    const { memberList, setMemberList, setMemberDetailList, memberDetailList, detailOpen, setDetailOpen } = useMembersStore();

    //Hook
    const { replaceDateHandler } = useComnHook();

    //초기셋팅
    const initDataHandler= () => {
        setBottomsheet({isOpen:false});
        setDetailOpen({});
    }

    /* ===== 예약회원리스트 ===== */
    //회원명검색
    //const [ searchWord, setSearchWord ] = useState('');
    const searchWord = useRef(null);
    //회원명검색창엔터
    const onSubmitSearch = (e) => {
        if(e.key == "Enter") {
            reservMembersListAPI(0);
        }
    };

    //예약회원관리리스트API
    const [ page, setPage ] = useState(20);
    const reservMembersListAPI = useCallback(async(type) => {//type-0.첫로딩 or Socket, 1.스크롤
            if(type == 0 || page == 20) { setMemberList([]); }
            const startDate = replaceDateHandler(datepicker.startDate);
            const endDate = replaceDateHandler(datepicker.endDate);
            const DATA = { "PARTNER_CD":partner.COMN_CD, "SEARCH_WORD":searchWord.current.value ?? '', "START_DATE":startDate, "END_DATE":endDate, "ROWNUM":type = 0 ? 20 : page };
            console.log(DATA)
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.REACT_APP_BACK_HOST}memberReservListForPartner.do`, DATA)
                .catch(err => {console.log("Error >", err); reject(err)})
                .then(response => { 
                    const res = response.data;
                    console.log(res)
                    if(res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                    if(res.message != "SUCCESS") { setPage(null); }
                    if(res.message == "SUCCESS") { 
                        if(page > 0 || type > 0) { setMemberList(memberList.concat(res.data)); }
                        else { setMemberList(res.data); }
                        
                        res.data.length >= 20 ? setPage(page + 20) : setPage(null);
                    }
                    resolve(true);
                });
            })
    });
    /* ===== 예약회원리스트 ===== */

    /* ===== 예약회원상세내역 ===== */
    //예약회원상세내역API
    const reservMembersDetailListAPI = useCallback( async(memberCd) => {
        try {
            setMemberDetailList([]);
            const DATA = { "MEMBER_CD":memberCd, "PARTNER_CD":partner.COMN_CD };
            console.log(DATA);
            const response = await axios.post(`${process.env.REACT_APP_BACK_HOST}memberReservDetailListForPartner.do`, DATA);
            const res = response.data;
            console.log(res)
            if(res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
            if (res.message == "SUCCESS") {
                setMemberDetailList(res.data);
            }
        } catch (error) { console.log("Error >" + error); }
    });

    //상세내역Show&Hide
    const [ isOpen, setOpen ] = useState(false);
    const [ detailItem, setDetailItem ] = useState({});
    const onDetailHandler = (item, idx) => {
        let obj = {};
        if(detailItem != item) {
            setOpen(true);
            item.IDX = idx;
            obj = item;
            reservMembersDetailListAPI(item.MEMBER_CD);
        } 
        setDetailItem(obj);
    }
    const handleDismissChange = (data) => {
        setOpen(data);
    }

    //Desktop화면카테고리필터
    const valueFilter = (idx, data, type) => {//type-1.차량, 2.차량번호 3.키로수
        console.log(idx);
        if(type == 0) {
            if( idx == 0 || 
                data[idx].MODEL_KOR_NAME != data[idx - 1].MODEL_KOR_NAME || 
                data[idx].LIC_NO != data[idx - 1].LIC_NO || data[idx].KMLE != data[idx - 1].KMLE && (data[idx].MODEL_KOR_NAME != undefined && data[idx].LIC_NO != undefined && data[idx].KMLE != undefined)) {
                return true;
            }
            return false;
        }
        if (type == 1) {
            if(idx == 0 || data[idx].MODEL_KOR_NAME != data[idx - 1].MODEL_KOR_NAME) {
                return `${data[idx].MODEL_KOR_NAME}`;
            }
            return;
        }
        if (type == 2) {
            if(idx == 0 || data[idx].LIC_NO != data[idx - 1].LIC_NO) {
                return `${data[idx].LIC_NO}`;
            }
            return;
        }
        if (type == 3) {
            if(idx == 0 || data[idx].KMLE != data[idx - 1].KMLE) {
                return `${data[idx].KMLE}km`;
            }
            return;
        }
    }

    //이전, 다음페이지이동
    const [ pager, setPager ] = useState(0);
    const onPageHandler = (type) => {//1.이전페이지, 2.다음페이지
        let pval = 0;
        if((type == 1 && pager <= 0) || (type == 2 && pager >= (memberDetailList.length - 1))) {
            return;
        }
        setPager(type == 1 ? pager - 1: type == 2 ? pager + 1 : 0);
    }
    /* ===== 예약회원상세내역 ===== */
    
    return { initDataHandler//초기셋팅
        , reservMembersListAPI, onSubmitSearch, searchWord, page//예약회원리스트
        , isOpen, detailItem, onDetailHandler, handleDismissChange, valueFilter//예약상세리스트
        , onPageHandler, pager//예약상세리스트bottomsheet
    };
}

export default useMembersHook;