import React, { useEffect } from 'react';

//style
import styles from 'src/asset/scss/component/attachFile.module.scss';
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import useAttachFileHook from 'src/webapp/hooks/component/attachFile.hook';

export default function AttachFile(props) {
    
    //Hook
    const { FontAwesomeIcon } = useComnHook();
    const { setMaxCountHandler//업로드갯수설정
        , chooseFileUploadHandler//파일선택
        , addFileHandler//파일저장
        , deleteFileHandler//파일삭제
        , files//파일배열useState
    } = useAttachFileHook();
    const file = props.file;

    //useEffect
    useEffect(() => {
        setMaxCountHandler(props.cnt);
    } ,[]);

    useEffect(() => {
        props.onData(files);//데이터상위컴포넌트로 전달
    } ,[files]);
	return (
		<>
        <div className={styles.fileArea}>
            <ul style={files.length > 0 ? null : {display : "none"}} className={styles.listContainer}>
                {files.map((file, id) =>{
                    return (
                    <li key={id}>
                        <i onClick={() => deleteFileHandler(file.url)}><FontAwesomeIcon icon={['fal', 'xmark']} /></i>
                        <div className={styles.imgArea}><img className="thumbnail" src={file.url} alt={`${file}-${id}`} /></div>
                        <label>{file.id}</label>
                    </li>
                    )
                })}
            </ul>
            
            <div className={styles.btnArea}>
                {file.length == 0 ?
                <button className={styles.submitButton} onClick={(e) => chooseFileUploadHandler(e, props.cnt)}>
                    <span>{props.label}</span><FontAwesomeIcon icon={['fal', 'rectangle-history-circle-plus']} />
                    <input type="file" id={props.cnt == 1 ? "hiddenFile" : props.cnt == 3 ? "hiddenFiles" : null} multiple onChange={addFileHandler} />
                </button>
                : ''}
            </div>
        </div>
        </>
	);
}