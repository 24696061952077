import React, { useRef, useState } from 'react';
import axios from "axios";
//axios
import useAxiosHook from '../comn/axios.hook';
//Store
import usePartnerStore from 'src/webapp/stores/partner.store';

const useItemRegUpdateHook = () => {
    //Store
    const { partner } = usePartnerStore();
    //hook
    const { axiosPostHandler } = useAxiosHook();
    const [isLock, setLock] = useState(false);
    const isLock2 = useRef(false);

    //ref
    const itemRef = useRef([]);
    const searchWordRef = useRef();

    /* ===== 팝업 on & off ===== */
    //팝업on&off
    const [isOpen, setOpen] = useState({ reg: false, update: false });
    const [form, setForm] = useState({});
    const onOpenHandler = (name, category, item) => {
        let temp = {};
        for (let prop in isOpen) {
            let value = false;
            if (prop == name) { value = true; }
            temp[prop] = value;
        }
        setOpen(temp);
        if (category != null) { setForm(item ?? { CATEGORY: category }); }
    }
    const handleDismissChange = (data) => {
        onOpenHandler(data, null, null);
    }
    /* ===== 팝업 on & off ===== */

    /* ===== 취급 브랜드 및 모델 ===== */
    //파트너취급모델리스트API
    const [vehicleGroupList, setVehicleGroupList] = useState([]);//취급브랜드전체리스트
    const [vehicleModelList, setVehicleModelList] = useState([]);//취급모델리스트
    const [checkedList, setCheckedList] = useState([]);
    const vehicleModelListByBrandAPI = async (type, item) => {//type - 1.등록, 2.수정
        const URL = 'partnerVehicleModelListByItemForPartner.do';
        const DATA = { "PARTNER_CD": partner.COMN_CD, "ITEM_CD": type == 1 ? 0 : item.ITEM_CD };
        console.log(DATA);
        axios.post(`${process.env.REACT_APP_BACK_HOST}` + URL, DATA)
            .catch(err => { console.log("Error >", err); })
            .then(response => {
                const res = response.data;
                const data = res.data;
                console.log(data);
                if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                if (res.message == "INVALID_DATA") { console.log("등록된 차량 모델이 없습니다."); }
                if (res.message == "SUCCESS") {
                    //브랜드set
                    setVehicleGroupList(data.filter((item, idx, callback) => idx === callback.findIndex((data) => data.BRAND_CD === item.BRAND_CD)));
                    //모델set
                    setVehicleModelList(res.data);
                    //아이템수정시 기존아이템체크리스트set
                    // setCheckedList(data.filter((item) => item.ITEM_USE_YN == "Y"));
                }
            });
    }
    //아이템수정 - 가격정보호출API
    const [ priceGroupList, setPriceGroupList ] = useState([]);
    const vehiclePriceGroupListAPI = async (item) => {//type - 1.등록, 2.수정
        const DATA = { "ITEM_CD": item.ITEM_CD };
        console.log(DATA);
        axios.post(`${process.env.REACT_APP_BACK_HOST}partnerItemInfoGroupListForPartner.do`, DATA)
            .catch(err => { console.log("Error >", err); })
            .then(response => {
                const res = response.data;
                const data = res.data;
                console.log(res);
                if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                if (res.message == "INVALID_DATA") { console.log("등록된 차량 모델이 없습니다."); }
                if (res.message == "SUCCESS") {
                    setPriceGroupList(data);
                }
            });
    }
    
    //가격설명선택API
        const [ selectPriceGroup, setSelectPriceGroup ] = useState({})
        const vehiclePriceListAPI = async(data, item) => {
            console.log(data);
            if(data === null){
                setSelectPriceGroup({});
                for(let i = 3; i < 14; i++) {
                    if(itemRef.current[i] !== undefined){
                        switch(i){
                            case 3: itemRef.current[3].value = null; break;
                            case 4: itemRef.current[4].value = null; break;
                            case 5: itemRef.current[5].value = null; break;
                            case 6: itemRef.current[6].checked = null; break;
                            case 7: itemRef.current[7].checked = null; break;
                            case 8: itemRef.current[8].value = null; break;
                            case 9: itemRef.current[9].value = null; break;
                            case 10: itemRef.current[10].value = 1; break;
                            case 11: itemRef.current[11].value = null; break;
                            case 12: itemRef.current[12].value = null; break;
                            case 13: itemRef.current[13].value = 1; break;
                        }}}
                        return;
            }
            const DATA = {ITEM_CD:item.ITEM_CD, SEQ_NO:data.SEQ_NO};
            const result = await axiosPostHandler(`partnerItemInfoListForPartner.do`, DATA);
            if (!result) {return;}
            if (result.message == "SUCCESS"){
                //result값 내 VEHICLE_CD 배열
                const resultVehicle = result.data.map(item => item.VEHICLE_CD);
                // 위 저장한 VEHICLE_CD 배열에 포함되는 모델 객체데이터
                const filterVehicle = vehicleModelList.filter((item) => resultVehicle.includes(item.VEHICLE_CD));
                setCheckedList(filterVehicle);
                setSelectPriceGroup(result.data[0]);

                for(let i = 3; i < 14; i++) {
                    if(itemRef.current[i] !== undefined){
                        switch(i){
                            case 3: itemRef.current[3].value = result.data[0].OIL_NAME; break;
                            case 4: itemRef.current[4].value = result.data[0].OIL_PRICE; break;
                            case 5: itemRef.current[5].value = result.data[0].OIL_QUOTA; break;
                            case 6: itemRef.current[6].checked = result.data[0].TIRE_FR == 1 ? true : false; break;
                            case 7: itemRef.current[7].checked = result.data[0].TIRE_FR == 2 ? true : false; break;
                            case 8: itemRef.current[8].value = result.data[0].PRICE; break;
                            case 9: itemRef.current[9].value = result.data[0].ROYALTY; break;
                            case 10: itemRef.current[10].value = result.data[0].DURATION_TIME; break;
                            case 11: itemRef.current[11].value = result.data[0].TOT_PRICE; break;
                            case 12: itemRef.current[12].value = result.data[0].DESCRIPTION; break;
                            case 13: itemRef.current[13].value = result.data[0].OIL_UNIT; break;
                        }}}

            }
        }
    /* ===== 취급 브랜드 및 모델 ===== */
    /* ===== 아이템 등록 및 수정 공통 ===== */
    //콤마제거 및 타입변환
    const removeCommaHandler = (str) => {
        if (str == '' || str < 0) { return parseInt(0); }

        return parseFloat(str.toString().replace(/,/g, ''));
    }
    //숫자세자리콤마
    const onNumberCommaHandler = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    /* ===== 아이템 등록 및 수정 공통 ===== */

    /* ===== 아이템 등록 및 수정 공통 ===== */
    //아이템명placeholder
    const [placeholder, setPlaceholder] = useState({ itemName1: '', itemName2: '', itemName3: '' });
    const initPlaceholder = (category) => {
        let obj = {};
        switch (category) {
            case 1: obj = { itemName1: '브랜드명', itemName2: '제품명', itemName3: '점도(00W00)' }; break;
            case 2: obj = { itemName1: '브랜드명', itemName2: '제품명', itemName3: '사이즈(000/00/00)' }; break;
            default: obj = { itemName1: '제품명', itemName2: '', itemName3: '' };
        }
        setPlaceholder(obj);
        return new Promise((resolve, reject) => { resolve(true); })
    }
    //SelectOption 초기셋팅
    const [unit, setUnit] = useState([]);//오일갯수
    const setUnitHandler = () => {
        let array = [{ name: "개수(단위:1개)", value: 1 }, { name: "ml(단위:100ml) ", value: 2 }];
        setUnit(array);
        return new Promise((resolve, reject) => { resolve(true); })
    }

    //모델체크핸들러
    const checkedHandler = (checked, item) => {//type 1. 모델선택 2. 전체선택
        if (checked) { setCheckedList([...checkedList, item]); }
        else { setCheckedList(checkedList.filter((el) => el != item)); }
    }
    //모델전체선택핸들러
    const allCheckboxCheckedHandler = (checked, word) => {
        if (word && word.length > 0) {
            if (checked) {
                const filterList = checkedList.concat(vehicleModelList.filter((item) => item.MODEL_KOR_NAME.includes(word)));
                setCheckedList(filterList.filter((item, pos) => filterList.indexOf(item) === pos));
            } else {
                setCheckedList(checkList => checkList.filter((item) => !item.MODEL_KOR_NAME.includes(word)))
            }
        } else {
            if (checked) {
                setCheckedList(vehicleModelList);
            } else {
                setCheckedList([]);
            }
        }
    }
    //브랜드로고클릭핸들러 
    const brandLogoClickHandler = (checked, word, brandCd) => {
        if (word && word.length > 0) {
            if (checked) {
                const filterList = checkedList.concat(vehicleModelList.filter((item) => item.MODEL_KOR_NAME.includes(word) && brandCd == item.BRAND_CD));
                setCheckedList(filterList.filter((item, pos) => filterList.indexOf(item) === pos));
            } else {
                setCheckedList(checkList => checkList.filter((item) => !(item.MODEL_KOR_NAME.includes(word) && brandCd == item.BRAND_CD)));
            }
        } else {
            if (checked) {
                const filterList = checkedList.concat(vehicleModelList.filter((item) => brandCd == item.BRAND_CD));
                setCheckedList(filterList.filter((item, pos) => filterList.indexOf(item) === pos));
            } else {
                setCheckedList(checkList => checkList.filter((item) => brandCd != item.BRAND_CD));
            }
        }
    }
    //모델검색핸들러
    const [searchWord, setSearchWord] = useState('');
    const onKeypressHandler = async (e) => {
        if (e.key != 'Enter') { return; }
        setSearchWord(e.target.value);
        document.querySelector('.searchInput').blur();//keyboardInvisible
    }
    //파일선택데이터끌어올리기
    const [files, setFiles] = useState([]);//사업자등록증이미지
    const handleFileChange = ((data) => {
        setFiles(data);
        inputValidateHandler();
    });

    //버튼활성화여부
    const [btnDisabled, setBtnDisabled] = useState(true);
    //입력검증핸들러
    const inputValidateHandler = () => {//10,13:select, 6,7:checkbox
        setOnTotalPriceHandler();
        let bval = false;
        for (let i = 0; i < itemRef.current.length; i++) {
            const data = itemRef.current[i] ?? null;
            if (data == null) { continue; }

            let val = null;
            switch (i) {
                case 4: val = data ?? 0; break;
                case 9: val = data ?? 0; break;
                case 10: val = data.value ?? 1; break;
                case 13: val = data.value ?? 1; break;
                case 6: val = data.checked; break;
                case 7: val = data.checked; break;
                default: val = data.value;
            }
            if (val.length == 0) { bval = true; break; }
        }
        if (!bval) {
            if (checkedList.length <= 0) {
                bval = true;
            }
        }
        setBtnDisabled(bval);
    }
    //총금액
    const setOnTotalPriceHandler = () => {//(오일단가 * 오일갯수) + 단가 + 기술료
        if (itemRef.current.length <= 0) { return; }

        const oilPrice = removeCommaHandler(itemRef.current[4] === undefined ? 0 : itemRef.current[4].value ?? 0);
        const oilQuota = removeCommaHandler(itemRef.current[5] === undefined ? 1 : itemRef.current[5].value ?? 1);
        const price = removeCommaHandler(itemRef.current[8].value ?? 0);
        const royalty = removeCommaHandler(itemRef.current[9].value ?? 0);
        const totPrice = Math.round((price * oilQuota) + oilPrice + royalty);//합금액 소숫점버림

        itemRef.current[11].value = onNumberCommaHandler(totPrice);
    }
    /* ===== 아이템 등록 및 수정 공통 ===== */

    /* ===== 아이템 등록 및 수정 API ===== */
    //아이템 등록 및 수정 이미지등록 API
    const partnerItemImgRegAPI = async (imgFile, list) => {
        if (isLock2.current) { return; }

        isLock2.current = true;
        const HEADER = { headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' } };
        const DATA = new FormData();
        let variables = { PARTNER_CD: partner.COMN_CD, TYPE: 3 };
        DATA.append('file', imgFile[0].file);
        DATA.append('requestDTO', new Blob([JSON.stringify(variables)]), { type: "application/json" });
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerItemImgRegForPartner.do`, DATA, HEADER)
                .catch(err => { console.log("Error >", err); reject(err); })
                .then(response => {
                    const res = response.data;
                    if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                    if (res.message == "INVALID_DATA") { console.log("데이터가 존재하지 않습니다"); }
                    if (res.message == "SUCCESS") {
                        /* 
                        let url = '';
                        switch(type) {
                            case 1: url = `partnerItemRegForPartner.do`; break;
                            case 2: url = `partnerItemUpdateForPartner.do`; break;
                        }
                        */
                        //itemRegUpdateAPI(url, res.data.SEQ_NO, item);
                        resolve(res.data.SEQ_NO);
                    }
                });
            setTimeout(function () { isLock2.current = false; }, 3000);
        })
    }

    //아이템등록 및 수정 API
    const itemRegUpdateAPI = async (type, seq, item, checklist, seqNo, item2) => {//type 1.등록 2.수정
        let itemName = '';
        switch (item.CATEGORY) {
            case 1: itemName = itemRef.current[0].value + ' ' + itemRef.current[1].value + ' ' + itemRef.current[2].value; break;
            case 2: itemName = itemRef.current[0].value + ' ' + itemRef.current[1].value + ' ' + itemRef.current[2].value; break;
            default: itemName = itemRef.current[0].value;
        }
        let DATA = [];
        for (let i = 0; checklist.length > i; i++) {
            if (checklist[i].VEHICLE_CD != null) {
                DATA.push({
                    PARTNER_CD: partner.COMN_CD
                    , ITEM_CD: type == 1 ? 0 : item.ITEM_CD
                    , CATEGORY: item.CATEGORY
                    , VEHICLE_CD: checklist[i].VEHICLE_CD
                    , ITEM_NAME: itemName
                    , DURATION_TIME: itemRef.current[10].value === undefined ? item2?.DURATION_TIME ?? 1 : itemRef.current[10].value
                    , TIRE_FR: (itemRef.current[6] === undefined && itemRef.current[6] === undefined) ? 0 : (itemRef.current[6].checked ? 1 : itemRef.current[7].checked ? 2 : 0)
                    , PRICE: removeCommaHandler(itemRef.current[8].value)
                    , ROYALTY: itemRef.current[9].value.length == 0 ? 0 : removeCommaHandler(itemRef.current[9].value)
                    , TOT_PRICE: removeCommaHandler(itemRef.current[11].value)
                    , OIL_NAME: itemRef.current[3] === undefined ? " " : itemRef.current[3].value
                    , OIL_QUOTA: itemRef.current[5] === undefined ? 0 : itemRef.current[5].value ?? 1
                    , OIL_PRICE: itemRef.current[4] === undefined ? -1 : itemRef.current[4].value.length == 0 ? 0 : removeCommaHandler(itemRef.current[4].value)
                    , IMG_CD: seq[0] ?? 0
                    , DESCRIPTION: itemRef.current[12] === undefined ? item2.DESCRIPTION ?? "" : itemRef.current[12].value
                    , OIL_UNIT: itemRef.current[13] === undefined ? item2?.OIL_UNIT ?? 1 : itemRef.current[13].value ?? 1
                    , SUBMIT_TYPE:type
                    , SEQ_NO: type === 2 ? seqNo : 0  
                });
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerItemRegAndUpdateForPartner.do`, DATA)
                .catch(err => { console.log("Error >", err); reject(err);})
                .then(response => {
                    const res = response.data;
                    console.log(res);
                    if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                    if (res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다"); }
                    if (res.message == "SUCCESS") { }
                    resolve(true);
                });
        })
    }

    //아이템삭제API
    const partnerItemDeleteAPI = async (item) => {
        if(!window.confirm('데이터를 삭제한 후에는 다시 복구할 수 없습니다.\n삭제하시겠습니까?')) { return; }
        return new Promise((resolve, reject) => {
            const DATA = [{ PARTNER_CD: partner.COMN_CD, ITEM_CD: item.ITEM_CD, DEL_YN: "Y", LAST_UPDATE_USER: partner.COMN_ID }];
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerItemDeleteArrayForJson.do`, DATA)
                .catch(err => { console.log("Error >", err); reject(err); })
                .then(response => {
                    const res = response.data;
                    if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다."); }
                    if (res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다"); }
                    if (res.message == "SUCCESS") { }
                    resolve(true);
                });
        })
    }
    /* ===== 아이템 등록 및 수정 API ===== */

    return {
        form, isOpen, onOpenHandler, handleDismissChange//팝업 on & off
        , placeholder, initPlaceholder, unit, setUnitHandler//초기셋팅
        , vehicleGroupList, vehicleModelList, vehicleModelListByBrandAPI//취급브랜드 및 모델
        , itemRef, searchWordRef//입력값
        , handleFileChange//데이터끌어올리기
        , files
        , btnDisabled, inputValidateHandler//버튼활성비활성
        , vehiclePriceGroupListAPI, priceGroupList//아이템수정가격정보호출
        , checkedList, setCheckedList, checkedHandler, searchWord, setSearchWord, allCheckboxCheckedHandler, brandLogoClickHandler//아이템등록 및 수정 체크상태useState
        , onKeypressHandler//모델검색핸들러
        , partnerItemImgRegAPI//팝업이미지등록API
        , itemRegUpdateAPI, vehiclePriceListAPI, selectPriceGroup, setSelectPriceGroup//팝업등록API
        , partnerItemDeleteAPI //팝업수정API
    };
}

export default useItemRegUpdateHook;