import React, { useState, useRef, useCallback } from 'react';
import axios from 'axios';
//Store
import usePartnerStore from 'src/webapp/stores/partner.store';
//Hook
import useRefreshAccessTokenHook from '../useRefreshAccessTokenHook';

const useMyPageHook = () => {
    //Store
    const { partner, info } = usePartnerStore();

    const isLock = useRef(false);//더블클릭방지

    /* ===== 취급차량 ===== */
    const [ vehicleGroupList, setVehicleGroupList ] = useState([]);
    //브랜드별취급차량리스트API
    const vehicleGroupListAPI = useCallback( async() => {
        const DATA = { "PARTNER_CD":partner.COMN_CD };
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerVehicleGroupListForPartner.do`, DATA)
                .catch(err => {console.log("Error >", err); reject(err);})
                .then((response) => {
                    const res = response.data;
                    if(res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                    if(res.message == "SUCCESS") { setVehicleGroupList(res.data); resolve(res.data);  }
                    resolve(res.data);
                });
        })
    });

    //취급차량 활(비)활성화 수정 API
    const vehicleUpdateAPI = useCallback( async(e, list, idx, item) => {
        if(isLock.current) { return; }

        let array = [...list];
        const DATA = { "PARTNER_CD":item.PARTNER_CD, "BRAND_CD":item.BRAND_CD, "USE_YN":array[idx].USE_YN == "Y" ? "N" : "Y" };
        axios.post(`${process.env.REACT_APP_BACK_HOST}partnerVehicleGroupUpdateForPartner.do`, DATA)
            .catch(err => {console.log("Error >", err); })
            .then((response) => {
                const res = response.data;
                if(res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                if(res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다"); }
                if(res.message == "SUCCESS") {
                    array[idx].USE_YN = array[idx].USE_YN == "Y" ? "N" : "Y";
                    setVehicleGroupList(array);
                    // vehicleGroupListAPI();
                }
            });
        setTimeout(function() { isLock.current = false; }, 3000);
    });
    /* ===== 취급차량 ===== */

    /* ===== 회원정보수정 ===== */
    const [ isOpen, setOpen ] = useState(false);
    //팝업 on & off
    const onOpenHandler = () => { setOpen(true); }
    const handleDismissChange = (data) => { setOpen(data); }

    //정비인원수셋팅
    const headCntList = useRef([]);
	const setHeadCntHandler = () => {
		for(let i = 1; i <= 10; i++) {
            headCntList.current[i] = { name:i+'명', value:i };
		}
	}

    //입력검증핸들러
    const updateRef1 = useRef([]);//bottomsheet
    const updateRef2 = useRef([]);//init
    const [ btnDisabled, setBtnDisabled ] = useState(true);
    const inputValidateRef1Handler = () => { inputValidateHandler(updateRef1); }
    const inputValidateRef2Handler = () => { inputValidateHandler(updateRef2); }
    const inputValidateHandler = (updateRef) => {
        let bval = true;
        for(let i = 0; i < (updateRef.current.length - 1); i++) {
            if(typeof updateRef.current[i].value == 'undefined') { bval = false;}
            else {
                if(updateRef.current[i].value.toString().length > 0) { bval = false; }
                if(updateRef.current[i].value.toString().length <= 0) { bval = true; break; }
            }
        }
        setBtnDisabled(bval);
    }

    //회원정보수정API
    const { partnersInfoAPI } = useRefreshAccessTokenHook();//회원정보호출API
    const partnerUpdateAPI = useCallback( async(type) => {//type - 1.bottomsheet, 2.init
        // if(isLock.current) { return; }
        const updateRef = type == 1 ? updateRef1 : updateRef2;
        const DATA = { "PARTNER_CD":partner.COMN_CD, "PARTNER_NAME":updateRef.current[0].value
            , "MECHANIC":updateRef.current[1].value ?? info.MECHANIC, "SUMMARY":updateRef.current[2].value
        };
        console.log(DATA);
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerMasterUpdateForPartner.do`, DATA)
                .catch(err => {console.log("Error >", err); reject(err);})
                .then((response) => {
                    const res = response.data;
                    if(res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                    if(res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다"); }
                    if(res.message == "SUCCESS") { partnersInfoAPI(); }//회원정보호출API
                    resolve(true);
                });
            setTimeout(function() { isLock.current = false; }, 3000);
        })
    });
    //===== 회원정보 수정 =====//

    //===== 고객센터(카카오) =====//
    //카카오채팅API연결
    const addKakaoChannel = useCallback(() => {
		if(window.Kakao) {
			const kakao = window.Kakao;
			const KAKAO_SCRIPT_INIT = `${process.env.REACT_APP_KAKAO_SCRIPT_INIT}`;
			const KAKAO_CHANNEL_ID = `${process.env.REACT_APP_KAKAO_CHANNEL_ID}`;

			if(!kakao.isInitialized()) { kakao.init(KAKAO_SCRIPT_INIT); }
			//if(!kakao.isInitialized()) { kakao.init(KAKAO_SCRIPT_INIT); }
			kakao.Channel.chat ({ channelPublicId:KAKAO_CHANNEL_ID });
		}
	});
    //===== 고객센터(카카오) =====//

    return { vehicleGroupList, setVehicleGroupList, vehicleGroupListAPI, vehicleUpdateAPI//취급차량
        , onOpenHandler, isOpen//회원정보수정-팝업
        , headCntList, setHeadCntHandler//회원정보수정-옵션
        , updateRef1, updateRef2, btnDisabled, inputValidateRef1Handler, inputValidateRef2Handler//회원정보수정-입력
        , partnerUpdateAPI//파트너수정API
        , handleDismissChange//회원정보수정팝업
        , addKakaoChannel//고객센터(카카오)
    };
}

export default useMyPageHook;