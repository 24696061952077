import React, { useEffect, useRef, useState } from 'react';
//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//hooks
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import useSignupHook from 'src/webapp/hooks/user/signup.hook';
//Comp
import TitleArea from '../../_titleArea';//타이틀
import StepArea from './step';//현재인덱스
import Holiday from '../bizDate/Holiday';//공휴일휴무선택
import Days from '../bizDate/Days';//현재인덱스

export default function Step03() {
	//Hook
	const { checkedStorage //스토리지유무확인
		, validatedNameHandler //상호명유효성검사
		, onhandleOnKeyPress//엔터이벤트
		, pageOnLoadHandler //버튼클릭
		, validated //유효성확인객체useState
		, signupRef//회원가입Ref
		, setValidated
	} = useSignupHook();
	const parentsRef = useRef();
	//useEffect
	useEffect(() => {
		// checkedStorage();//쿠키유무확인
		// signupRef.current[0].focus();
	}, []);

	/* =============== 시간데이터호출 =============== */
	const { timeListAPI } = useComnHook();//시간리스트API
    useEffect(() => {
        timeListAPI();//시간리스트API
    }, []);
	/* =============== 시간데이터호출 =============== */
    /* =============== 휴게시간유무설정 =============== */
    const [ isHoliday,setHoliday ] = useState(true);
    /* =============== 휴게시간유무설정 =============== */

	return (
		<>
		<section className={["container", styles.userContainer].join(" ")}>
			<div className={styles.wrapper}>
				{/* 타이틀 */}
				<TitleArea usePrev={true} title={`업체 등록하기`} subtitle={`영업시간을 선택해주세요`} isMobile={true} />{/* 타이틀 */}
				{/* 스텝 */}
				<StepArea step03={true} idx={3} />
				<div className={"flickPanel"}>
					<Holiday isHoliday={isHoliday} setHoliday={setHoliday} />
					<Days ref={parentsRef} onValid={setValidated} />
				</div>
				{/* 버튼 */}
				<div className={["fixedPanel", styles.btnArea].join(" ")}>
					<button onClick={() => parentsRef.current.sumbitHandler(isHoliday)}
						disabled={validated.key == null ? true : validated.key ? true : false}
						className="submitButton">다음</button>
				</div>
			</div>
		</section>
		</>
	);
}