import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
//style

//Hook
import useRefreshAccessTokenHook from 'src/webapp/hooks/useRefreshAccessTokenHook';
//Comp
import Spinner from 'src/webapp/views/component/loading/Spinner';
import {
    Login
    , Signup, SignupId, SignupPasswd
    , Auth, AuthEmail, AuthTel, Verify
    , InfoStep01, InfoStep02, InfoStep03, InfoStep04, InfoStep05, InfoStep06 ,InfoStep03Test
    , Success, Fail
    , FindId, FindIdSuccess, FindPasswd, FindPasswdReset, FindVerify
    , Mypage
} from 'src/webapp/views/user';
import { Dashboard, Notify, Schedule, Items, Sales, Members, Calc } from 'src/webapp/views';
import NotFound from './NotFound';
import { getCookie } from 'src/webapp/config/cookie';

export default function Router(props) {
    //location
    const location = useLocation();
    const locate = location.pathname;
    //navigator
    const navigator = useNavigate();
    //Hook
    const { partnerTokenCheckAPI } = useRefreshAccessTokenHook();
    //routerElement
    const [isPending, setPending] = useState(true);
    const [isState, setState] = useState(null);
    //useEfeect
    useEffect(() => {
        Promise.all([partnerTokenCheckAPI()])
            .catch((error) => { navigator("/", { replace: true }); })
            .then((values) => {
                setState(values[0]);
                if ((locate.includes("/find") || locate.includes("/signup"))) {
                    const refreshToken = getCookie("NIDPR");
                    if(refreshToken != undefined || refreshToken != null) { navigator("/", {replace:true});  }
                    setPending(false);
                    return;
                }
                if ((locate !== "/" && !values[0])) {
                    navigator("/", { replace: true });
                }//로그인화면으로이동
                setPending(false);
            });
        }, [locate]);
    const routes = [
        { path: "/", element: !isPending && !isState ? <Login /> : !isPending && isState ? <Dashboard /> : <Spinner /> }
        , {
            path: "/signup"
            , children: [{ index: true, element: <Signup /> }
                , { path: "id", element: <SignupId /> }
                , { path: "password", element: <SignupPasswd /> }
                , { path: "auth", element: <Auth /> }
                , { path: "auth/email", element: <AuthEmail /> }
                , { path: "auth/tel", element: <AuthTel /> }
                , { path: "auth/verify", element: <Verify /> }
                , { path: "info/1", element: <InfoStep01 /> }
                , { path: "info/2", element: <InfoStep02 /> }
                , { path: "info/3", element: <InfoStep03Test /> }
                , { path: "info/4", element: <InfoStep04 /> }
                , { path: "info/5", element: <InfoStep05 /> }
                , { path: "info/6", element: <InfoStep06 /> }
                , { path: "success", element: <Success /> }
                , { path: "fail", element: <Fail /> }
                , { path: "test", element: <InfoStep03Test /> }
            ]
        }
        , {
            path: "/find"
            , children: [{ index: true, path: "id", element: <FindId /> }
                , { path: "id/success", element: <FindIdSuccess /> }
                , { path: "passwd", element: <FindPasswd /> }
                , { path: "passwd/reset", element: <FindPasswdReset /> }
                , { path: "verify", element: <FindVerify /> }
            ]
        }
        , { path: "/dashboard", element: <Dashboard /> }
        , { path: "/mypage", element: <Mypage /> }
        , { path: "/schedule", element: <Schedule /> }
        , { path: "/item", element: <Items /> }
        , { path: "/sales", element: <Sales /> }
        , { path: "/members", element: <Members /> }
        , { path: "/notify", element: <Notify /> }
        , { path: "/calc", element: <Calc /> }
        // , { path: "/success", element: Success }
        , { path: "/notfound", element: <NotFound /> }
    ];
    let router = useRoutes(routes);

    return (router);
}