import React, { useState, useRef, useCallback } from "react";
import axios from "axios";

//style
import styles from 'src/asset/scss/style/schedule.module.scss';
//Store
import useComnStore from "src/webapp/stores/comn.store";
import usePartnerStore from "src/webapp/stores/partner.store";
import useScheduleStore from "src/webapp/stores/schedule.store";
//Hook
import useComnHook from "src/webapp/hooks/comn/comn.hook";

const useScheduleHook = () => {
    //Store
    const { datepicker } = useComnStore();
    const { partner, info } = usePartnerStore();
    const { dayList } = useScheduleStore();

    //ref
    const reserveInRef = useRef([]);
    const reserveRejectRef = useRef([]);
    const reserveSuccessRef = useRef([]);
    const { replaceDateHandler//날짜포맷변경(yyyyMMdd)
        , excelExportAPI//엑셀다운로드API
        , removeCommaHandler//콤마제거및타입변환
    } = useComnHook();

    const isLock = useRef(false);
    //const [isLock, setLock] = useState(false);

    /* ===== 스케줄리스트API ===== */
    //날짜리스트API
    const [timeList, setTimeList] = useState([]);
    const scheduleTimeListAPI = useCallback(async (date) => {
        const endDate = replaceDateHandler(date.endDate);
        const DATA = { "PARTNER_CD": partner.COMN_CD, "RESERV_DT": endDate };
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerScheduleDayTimeListForPartner.do`, DATA)
                .catch(err => { console.log("Error >", err); reject(err); })
                .then(response => {
                    const res = response.data;
                    if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                    if (res.message == "SUCCESS") { setTimeList(res.data); }
                    resolve(res.data);
                });
        });
    });
    //날짜별스케줄리스트API
    const [scheduleList, setScheduleList] = useState(null);
    const scheduleDayListAPI = useCallback(async (date) => {
        const endDate = replaceDateHandler(date.endDate);
        const DATA = { "PARTNER_CD": partner.COMN_CD, "RESERV_DT": endDate };
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerScheduleDayListForPartner.do`, DATA)
                .catch(err => { console.log("Error >", err); reject(err); })
                .then(response => {
                    const res = response.data;
                    if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다");}
                    if (res.message == "SUCCESS") { setScheduleList(res.data); }
                    resolve(res.data);
                });
        });
    });

    const setListHandler = (data) => {
        setScheduleList(data);
    }

    //스케쥴러-정비사인원API
    // const [mechanicCnt, setMechanicCnt] = useState(0);
    // const mechanicCntAPI = useCallback(async (date) => {
    //     const endDate = replaceDateHandler(date.endDate);
    //     const DATA = { "PARTNER_CD": partner.COMN_CD, "RESERV_DT": endDate };
    //     return new Promise((resolve, reject) => {
    //         axios.post(`${process.env.REACT_APP_BACK_HOST}partnerScheduleDayMechanicCntForPartner.do`, DATA)
    //             .catch(err => { console.log("Error >", err); reject(err); })
    //             .then(response => {
    //                 const res = response.data;
    //                 if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
    //                 if (res.message == "SUCCESS") { setMechanicCnt(res.data); }
    //                 resolve(res.data);
    //             });
    //     });
    // });
    /* ===== 스케줄리스트API ===== */
    /* ===== D+14스케줄리스트 ===== */
    //D+14상태리스트API
    const [afterDayList, setAfterDayList] = useState([]);
    const after14DaysListAPI = useCallback(async () => {
        const DATA = { "PARTNER_CD": partner.COMN_CD };
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerScheduleAfterDaysListForPartner.do`, DATA)
                .catch(err => { console.log("Error >", err); reject(err); })
                .then(response => {
                    const res = response.data;
                    if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                    if (res.message == "SUCCESS") { setAfterDayList(res.data); resolve(res.data); }

                });
        });
    });
    /* ===== D+14스케줄리스트 ===== */

    /* ===== 스케줄리스트 ===== */
    //엑셀다운로드
    const excelExportHandler = useCallback(async (date) => {
        const endDate = replaceDateHandler(date.endDate);

        const data = { "PARTNER_CD": partner.COMN_CD, "RESERV_DT": endDate };
        const url = `${process.env.REACT_APP_BACK_HOST}partnerScheduleDayListExcel.do`;
        const filename = `[두바키 파트너스]${endDate}스케줄`;

        excelExportAPI(data, url, filename);//엑셀다운로드API
    });

    //정비원별예약상태카운트 - 2.새요청, 3.예약완료, 4.입고완료, 5.정비완료, 6. 이력등록완료
    const setStateCount = (idx) => {
        return dayList && dayList ? dayList.filter((item) => item.MECHANIC_CD == idx && (item.STATE >= 2 && item.STATE <= 6)).length : 0;
    }

    //예약상태별컨텐츠내용CSSStyle
    const setStatusStyle = (state) => {
        switch (state) {
            case 0: return styles.status0;
            case 1: return styles.status1;
            case 2: return styles.status2;
            case 3: return styles.status3;
            case 4: return styles.status4;
            case 5: return styles.status5;
            case 6: return styles.status6;
            case 9: return styles.status9;
        }
    }

    //스케줄개별클릭핸들러
    const onItemHandler = (item, list) => {
        if (item.STATE <= 1) {//스케줄예약변경API(예약불가 및 예약가능)
            Promise.all([scheduleUpdateAPI(item)]).then((value) => {
                for (let i = 0; i < list.length; i++) {
                    if (list[i].SEQ_NO == item.SEQ_NO) {
                        item.STATE = item.STATE == 0 ? 1 : item.STATE == 1 ? 0 : null;
                        let newArr = [...list];
                        newArr[i] = item;
                        setScheduleList(newArr);
                        break;
                    }
                }
            });
        }
        if (item.STATE >= 2 && item.STATE <= 5) {
            let state = '';
            switch (item.STATE) {//1.예약가능, 2.새요청, 3.예약완료, 4.입고완료, 5.정비완료, 6. 이력등록완료, 9.예약가능시간만료, 0.예약불가
                case 2: state = 'new'; break;
                case 3: state = 'in'; break;
                case 4: state = 'success'; break;
                case 5: state = 'success'; break;
                default: state = 'reject'; break;
            }
            onOpenHandler(state, true);//팝업오픈
            scheduleMemberReservAPI(item);//회원예약정보확인
        }
    }

    //스케줄예약변경API(예약불가 및 예약가능) - 0.예약불가, 1.예약가능
    const scheduleUpdateAPI = async (item) => {
        if (!isLock.current) {
            isLock.current = true;

            const DATA = { "PARTNER_CD": partner.COMN_CD, "MECHANIC_CD": item.MECHANIC_CD, "SEQ_NO": item.SEQ_NO, "STATE": item.STATE };
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.REACT_APP_BACK_HOST}partnerScheduleUpdateForPartner.do`, DATA)
                    .catch(err => { console.log("Error >", err); reject(err); })
                    .then(response => {
                        const res = response.data;
                        if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                        if (res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다"); }
                        //if(res.message == "SUCCESS") { scheduleDayListAPI(); }
                        resolve(true);
                    });
                setTimeout(function () { isLock.current = false; }, 300);
            });
        }
    }
    const scheduleUpdateByMechanicAPI = async (cd, mechanic, date, status) => {//예약상태-1.예약가능, 0.예약불가
        if (!isLock.current) {
            isLock.current = true;
            const endDate = replaceDateHandler(date.endDate);
            const DATA = { "PARTNER_CD": cd, "MECHANIC_CD": mechanic, "RESERV_DT": endDate, "STATE": status };
                axios.post(`${process.env.REACT_APP_BACK_HOST}partnerScheduleUpdateByMechanicForPartner.do`, DATA)
                    .catch(err => { console.log("Error >", err); })
                    .then(response => {
                        const res = response.data;
                        if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                        if (res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다"); }
                        if(res.message == "SUCCESS") { scheduleDayListAPI(date); }
                    });
                setTimeout(function () { isLock.current = false; }, 300);
        }
    }
    /* ===== 스케줄리스트 ===== */

    /* ===== 스케줄팝업 ===== */
    //예약상태별팝업 on & off
    const [isOpen, setOpen] = useState({ new: false, reject: false, in: false, success: false });
    const onOpenHandler = (state, value) => {
        let obj = {};
        Object.keys(isOpen).map(item => {
            if (item == state) { obj[item] = value; }
            else { obj[item] = false; }
        });
        if (state.length > 0) { return setOpen(obj); }
    }
    //const handleDismissChange = () => { setOpen({ new:false, reject:false, in:false, success:false }); }

    //회원예약프로필 API
    const [profile, setProfile] = useState({});
    const scheduleMemberReservAPI = async (item) => {
        console.log(item);
        const DATA = { "PARTNER_CD": partner.COMN_CD, "SEQ_NO": item.SEQ_NO };
        console.log(DATA);
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerScheduleMemberReservForPartner.do`, DATA)
                .catch(err => { console.log("Error >", err); })
                .then(response => {
                    const res = response.data;
                    console.log(res);
                    if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                    if (res.message == "SUCCESS") { setProfile(res.data); }
                });
        });
    }

    //예약요청사항
    const [type, setType] = useState('');
    const profileRequest = async (item) => {
        let obj = '';
        for (let i = 0; i < 4; i++) {
            let name = '';
            switch (i) {
                case 0: name = item.TYPE_AIR !== undefined && item.TYPE_AIR == 0 ? null : '공기압'; break;
                case 1: name = item.TYPE_COOL !== undefined && item.TYPE_COOL == 0 ? null : '냉각수'; break;
                case 2: name = item.TYPE_TIRE !== undefined && item.TYPE_TIRE == 0 ? null : '타이어'; break;
                case 3: name = item.TYPE_BRAKE !== undefined && item.TYPE_BRAKE == 0 ? null : '브레이크 패드 마모'; break;
            }
            if (name != null) {
                if (obj.length > 0) { name = ' | ' + name; }
                obj += name;
            }
        }
        setType(obj);
    }
    const [category, setCategory] = useState('');
    const categoryFilter = async (item) => {
        let obj = '';
        let itemLength = item.length;
        for (let i = 0; itemLength > i; i++) {
            let name = '';
            name = item[i].CATEGORY == 1 ? '엔진오일' : item[i].CATEGORY == 2 ? '타이어' : item[i].CATEGORY == 3 ? '정기점검' : item[i].CATEGORY == 4 ? '기타' : null;
            if (name != null) {
                if (obj.length > 0) { name = ' | ' + name; }
                obj += name;
            }
        }
        setCategory(obj)
    }
    const [buttonState, setButtonState] = useState(false);
    const [reserveInForm, setReserveInForm] = useState({ KMLE: 0, OIL_CHG: 0 });
    const [rejectForm, setRejectForm] = useState({ CD: 0, CONTENT: '' });
    const [successForm, setSuccessForm] = useState({ RESULT: '', REMARK: '' });
    //입력핸들러
    const onFormChangeHandler = (e, state) => {//0.예약거절, 4.입고완료, 5.정비완료
        const { value, name } = e.target;
        switch (state) {
            // case 0: setRejectForm({ ...rejectForm, [name]: value }); break;
            case 4:
                if (profile.CATEGORY_SEQ == 1) {
                    if ((reserveInRef.current[0] && reserveInRef.current[0].value == "") || (reserveInRef.current[1] && reserveInRef.current[1].value == "")) {
                        if (buttonState) {
                            setButtonState(false);
                        }
                    }
                    return;
                }
                if ((reserveInRef.current[0] && reserveInRef.current[0].value == "")) {
                    if (buttonState) {
                        setButtonState(false);
                    }
                    return;
                }
                if (!buttonState) {
                    setButtonState(true);
                }
                break;
            // case 5: reserveSuccessRef.current[0].value = 5; console.log(reserveSuccessRef.current[0].value); break;
        }
    }
    //전달사항저장
    const onSuccessFormSelectHandler = (name, target) => {
        if (target.value != 5) {
            // setSuccessForm({ ...successForm, [name]: target.name });
            reserveSuccessRef.current[1].value = target.name;
        }
        if (target.value == 5) {
            return;
        }
    }
    //예약상태업데이트 API
    const scheduleStateUpdateAPI = async (data, reqState, update, date, content) => {// update 0.예약거절, 1. 예약완료, 2. 입고완료, 3. 정비완료, 4. 이력등록완료
        if (isLock.current) { return; }
        isLock.current = true;
        const DATA = { "MEMBER_CD": data.MEMBER_CD, "PARTNER_CD": partner.COMN_CD, "ORDER_NO": data.ORDER_NO, "RESERV_STATE": data.STATE, "SCHEDULE_STATE": reqState };
        console.log(DATA);
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerScheduleStateUpdateForPartner.do`, DATA)
                .catch(err => { console.log("Error >", err); reject(err); })
                .then(response => {
                    setTimeout(function () { isLock.current = false; }, 3000);
                    const res = response.data;
                    console.log(res)
                    if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                    if (res.message == "SUCCESS") {
                        switch (update) {
                            case 0: scheduleStateRejectAPI(data, date, content); break;//거절사유등록API
                            case 2: memberVehicleUpdateAPI(data, date); break;//회원차량정보업데이트API
                            case 4: memberServiceHisRegAPI(data, date); break;//회원차량정보업데이트API
                            default: scheduleDayListAPI(date); break;
                        }
                    }
                    resolve(true);
                });
        })
    }
    /* ===== 스케줄팝업 ===== */
    /* ===== 수락거절 ===== */
    //거절사유등록API
    const scheduleStateRejectAPI = async (data, date, content) => {
        const DATA = {
            "ORDER_NO": data.ORDER_NO, "PARTNER_CD": partner.COMN_CD, "MEMBER_CD": data.MEMBER_CD
            , "REJECT_CODE": reserveRejectRef.current[0].value ? reserveRejectRef.current[0].value : 1
            , "REJECT_CONTENT": reserveRejectRef.current[1].value.length > 0 ? reserveRejectRef.current[1].value : content[reserveRejectRef.current[0].value ? (reserveRejectRef.current[0].value - 1) : 0].name 
        };
        axios.post(`${process.env.REACT_APP_BACK_HOST}partnerScheduleStateRejectForPartner.do`, DATA)
            .catch(err => { console.log("Error >", err); })
            .then(response => {
                const res = response.data;
                if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); scheduleDayListAPI(date);}
                if (res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다"); }
                if (res.message == "SUCCESS") { scheduleDayListAPI(date); }
            });
    }
    /* ===== 수락거절 ===== */

    /* ===== 입고완료 ===== */
    //회원차량정보수정API
    const memberVehicleUpdateAPI = async (data, date) => {
        const DATA = {
            "MEMBER_CD": data.MEMBER_CD, "ITEM_CD": data.VEHICLE_CD
            , "KMLE": reserveInRef.current[0].value.replaceAll(',', '') ?? 0, "OIL_CHG": reserveInRef.current[1] && reserveInRef.current[1].value !== "" ? reserveInRef.current[1].value.replaceAll(',', '') : 0
            , "SUBMIT_TYPE":3
        };
        console.log(DATA);
        axios.post(`${process.env.REACT_APP_BACK_HOST}memberVehicleUpdateForPartner.do`, DATA)
            .catch(err => { console.log("Error >", err); })
            .then(response => {
                const res = response.data;
                console.log(res)
                if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                if (res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다"); }
                if (res.message == "SUCCESS") { scheduleDayListAPI(date); }
            });
    }
    /* ===== 입고완료 ===== */

    /* ===== 정비완료팝업 ===== */
    //파일선택데이터끌어올리기
    const [files, setFiles] = useState([]);//정비이력이미지
    const handleFileChange = (data) => {
        setFiles(data);
    };

    //정비완료 or 이력등록완료
    const onSwitchHandler = (data, date) => {
        if (reserveSuccessRef.current[1].value.length > 0) {
            if(files.length <= 0) {
                alert("이미지를 등록해 주세요.");
                return;
            }else {
                serviceHisUploadFileAPI(data, date);
            }
        } else {
            scheduleStateUpdateAPI(data, 5, 3, date);
        }
    }

    //정비이력이미지업로드API
    const serviceHisUploadFileAPI = async (data, date) => {
        if (files.length > 0) {
            try {
                let variables = { MEMBER_CD: data.MEMBER_CD, ORDER_NO: data.ORDER_NO };

                const HEADERS = { headers: { 'enctype': 'multipart/form-data', charset: 'utf-8' } };
                const DATA = new FormData();
                for (let i = 0; i < files.length; i++) {
                    DATA.append('file', files[i].file);
                }
                DATA.append('requestDTO', new Blob([JSON.stringify(variables)]), { type: "application/json" });
                const response = await axios.post(`${process.env.REACT_APP_BACK_HOST}memberServiceHisUploadFileForPartner.do`, DATA, HEADERS);
                const res = response.data;
                if (res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다"); }
                if (res.message == "SUCCESS") {
                    scheduleStateUpdateAPI(data, 6, 4, date);
                }
            } catch (error) { console.log("Error >" + error); }
        }
    }

    //차량정비완료 및 차량이력등록완료API
    const memberServiceHisRegAPI = async (data, date) => {
        try {
            const DATA = {
                "ORDER_NO": data.ORDER_NO, "MEMBER_CD": data.MEMBER_CD, "ITEM_CD": data.VEHICLE_CD, "TYPE": 1, "PARTNER_CD": partner.COMN_CD
                , "STORE_NAME": info.PARTNER_NAME, "CATEGORY": data.CATEGORY_SEQ, "RESULT": reserveSuccessRef.current[1].value, "REMARK": reserveSuccessRef.current[2].value
            };
            console.log(DATA);
            const response = await axios.post(`${process.env.REACT_APP_BACK_HOST}memberServiceHisRegForPartner.do`, DATA);
            const res = response.data;
            if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
            if (res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다"); }
            if (res.message == "SUCCESS") { scheduleDayListAPI(date); }
        } catch (error) { console.log("Error >" + error); }
    }
    /* ===== 정비완료팝업 ===== */

    return {
        buttonState, files//공통
        , timeList, scheduleTimeListAPI, scheduleList, scheduleDayListAPI, setListHandler//스케줄리스트
        , afterDayList, after14DaysListAPI//D+14상태리스트
        , profile//예약팝업공통(예약정보)
        , scheduleStateUpdateAPI, scheduleUpdateByMechanicAPI//예약정보업데이트
        , excelExportHandler, setStateCount, setStatusStyle//스케줄리스트
        , onItemHandler, onOpenHandler, isOpen, onFormChangeHandler//팝업공통
        , profileRequest, type, categoryFilter, category//예약자정보
        , rejectForm, setRejectForm, reserveRejectRef//예약거절
        , reserveInForm, reserveInRef//입고완료
        , successForm, onSuccessFormSelectHandler, handleFileChange, onSwitchHandler, reserveSuccessRef//정비완료
    };
}

export default useScheduleHook;