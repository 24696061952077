import React, { useEffect, Suspense } from 'react';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//DaumPostcode
import DaumPostcode from 'react-daum-postcode';
//Hook
import useSignupHook from 'src/webapp/hooks/user/signup.hook';
//Comp
import _titleArea from '../../_titleArea';
import __step from './step';

export default function Step02() {
    //Hook
    const { checkedStorage //스토리지유무확인
        , postcodeOpenHandler//주소검색팝업
        , onhandleOnKeyPress//엔터이벤트
        , pageOnLoadHandler //버튼클릭
        , validated //유효성확인객체useState
        , isOpen//팝업오픈여부useState
        , addr//주소값useState
        , signupRef//회원가입Ref
    } = useSignupHook();
    //useEffect
    useEffect(() => {
        checkedStorage();//쿠키유무확인
    }, []);

    return (
        <>
            <section className={["container", styles.userContainer].join(" ")}>
                <div className={styles.wrapper}>
                    <_titleArea usePrev={true} title={`업체 등록하기`} subtitle={`주소를 입력해주세요`} isMobile={true} />{/* 타이틀 */}
                    <__step step02={true} idx={2} />{/* 스텝 */}

                    {/* 입력필드 */}
                    <div className={["flickPanel", styles.inputArea].join(" ")}>
                        <div className={styles.inputField}><label>주소 검색{isOpen}</label>
                            <div className={styles.postcodeArea}>
                                <b><FontAwesomeIcon icon={['fal', 'magnifying-glass']} /></b>
                                <input onClick={postcodeOpenHandler.clickButton}
                                    value={addr.addr1}
                                    type="text" inputMode="text"
                                    placeholder="주소를 입력해주세요" />
                                {/* 주소검색팝업 */}
                                {isOpen ?
                                    <div className={styles.postcodePopup}>
                                        <DaumPostcode autoClose={true} onComplete={postcodeOpenHandler.selectAddress} defaultQuery='' />
                                    </div>
                                    : null}
                            </div>
                        </div>
                        <div className={styles.inputField}><label>상세주소</label>
                            <div>
                                <input ref={el => (signupRef.current[0] = el)}
                                    onKeyDown={(e) => onhandleOnKeyPress(e, 7)}
                                    type="text" inputMode="text"
                                    placeholder="상세주소를 입력해주세요" />
                            </div>
                        </div>
                    </div>

                    {/* 버튼 */}
                    <div className={["fixedPanel", styles.btnArea].join(" ")}>
                        <button onClick={() => pageOnLoadHandler(7)}
                            disabled={validated.key == null ? true : validated.key ? true : false}
                            className="submitButton">다음</button>
                    </div>
                </div>
            </section>
        </>
    );
}