import React, { useEffect, Suspense, lazy, useCallback, useState } from 'react';

//observer
import { useInView } from 'react-intersection-observer';
//style
import styles from 'src/asset/scss/style/members.module.scss';
//Store
import useComnStore from 'src/webapp/stores/comn.store';
import useMembersStore from 'src/webapp/stores/members.store';
import usePartnerStore from 'src/webapp/stores/partner.store';
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import useDatepickerHook from 'src/webapp/hooks/component/datepicker.hook';
import useMembersHook from 'src/webapp/hooks/members/members.hook';
//Comp
import NavBar from 'src/webapp/views/component/NavBar';
import TitleBar from 'src/webapp/views/component/TitleBar';
import Spinner from '../component/loading/Spinner';
const lazyWithPreload = importFunction => {
    const Component = lazy(importFunction);
    Component.preload = importFunction;

    return Component;
};
const _member            = lazyWithPreload(() => import('./_member'));
const BsSearchDateRange  = lazyWithPreload(() => import('src/webapp/views/component/BsSearchDateRange'));

export default function Members() {

    //Store
    const { datepicker } = useComnStore();
    const { isState } = usePartnerStore();
    const { memberList } = useMembersStore();

    //Hook
    const { FontAwesomeIcon
        , compPreload//Preload
        , initDateHandler//날짜초기셋팅
    } = useComnHook();
    const { onDatepickerHandler } = useDatepickerHook();//날짜팝업
    const { initDataHandler//초기셋팅
        , reservMembersListAPI//예약회원리스트API
        , page//infinity페이지useState
        , searchWord//검색어입력useState
        , onSubmitSearch//검색창엔터
    } = useMembersHook();

    //useEffect
    useEffect(() => {
        const comp = [ _member, BsSearchDateRange ];
        compPreload(comp);//preload

        initDataHandler();//초기셋팅
        initDateHandler(-7, 0);//날짜초기셋팅(1년전~당일)
    }, []);

    const [isPending, setPending] = useState(true);
    const initMemberData = useCallback(async() => {
        if (isState && !datepicker.isOpen && datepicker.startDate != '' && datepicker.endDate != '') {
            setPending(true);
            Promise.all([ reservMembersListAPI(0) ]).then((e) => {
                setPending(false);
            });
        }
    }, [datepicker, isState])

    useEffect(() => {
        initMemberData()
    }, [initMemberData]);
    //infinity observer
    const [ ref, inView ] = useInView({ threshold: .5 });
    useEffect(() => {
        if(inView && (page > 20 || page != null)) { reservMembersListAPI(1); }//알림리스트API
    }, [inView]);
    console.log(page);

    return (
        <>
        <section className="fixedPanel container main">
            <NavBar id={5} />
            <div className="layer">
                <div className="fixedPanel">
                    <TitleBar title={`회원관리`} />
                    {/* 검색창 */}
                    <hr className="separate" />
                    <div className="searchArea">
                        <div className="dateArea">
                            <div className="datepicker">{datepicker.startDate + ` - ` + datepicker.endDate}</div>
                            <button onClick={(e) => onDatepickerHandler("isOpen", true)}
                                className="btnCalendar"><FontAwesomeIcon icon={['fal', 'calendar-lines']} /></button>
                        </div>
                        <div>
                            <div className="cntArea">
                                <em><span>방문자수&nbsp;</span>
                                    {memberList && memberList.length > 0 ? memberList.length + "명" : 0 + "명"}
                                </em>
                            </div>
                        </div>
                    </div>
                    <div className={styles.searchWordArea}>
                        <div>
                            <FontAwesomeIcon icon={['fal', 'magnifying-glass']} />
                            <input ref={el => (searchWord.current = el)}
                                onKeyDown={(e) => onSubmitSearch(e)}
                                type="text" inputMode="search" placeholder={`회원을 검색해보세요`} />
                        </div>
                    </div>
                </div>
            <div className="flickPanel">
                {/* 회원리스트 */}
                {isPending ? (<div className="fullHeight"><Spinner isPending={true} /></div>) : (<Suspense fallback={null}><_member /></Suspense>)}
                {page == null && memberList.length > 0 ? 
                    <div className="loading infiniteScroll"><label>회원내역을 모두 확인했습니다.</label></div>
                :  page > 0  && memberList.length > 0 ? 
                    <div ref={ref} className="loading infiniteScroll"><div className="spinner"></div></div>
                : null
                }
            </div>
        </div>
    </section>

    {/* bottomSheet */}
    <Suspense fallback={null}><BsSearchDateRange /></Suspense>
    </>
    );
}