import React, { useEffect, useState } from 'react';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//style
import styles from 'src/asset/scss/style/userSignup.module.scss';
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/select.scss';
//store
import useComnStore from 'src/webapp/stores/comn.store';
//hooks
import useComnHook from 'src/webapp/hooks/comn/comn.hook';

export default function Holiday(props) {
    const { isHoliday,setHoliday } = props;

	return (
        <div className={styles.holidayArea}>
            <h5>공휴일에 쉬나요?</h5>
            <div className={styles.btnArea}>
                <button className={isHoliday ? styles.selected : null} onClick={(e) => setHoliday(true)}>쉬어요</button>
                <button className={!isHoliday ? styles.selected : null} onClick={(e) => setHoliday(false)}>영업해요</button>
            </div>
        </div>
	);
}