import React, { useCallback, useRef, useState } from 'react';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//bottomSheet
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'src/asset/scss/plugin/bottomsheet/style.scss';
import 'src/asset/scss/plugin/bottomsheet/bottomsheet.scss';
//Hook
import useSignupHook from 'src/webapp/hooks/user/signup.hook';
//Comp
import Spinner from 'src/webapp/views/component/loading/Spinner';
import Days from '../comp/bizDate/Days';
import Holiday from '../comp/bizDate/Holiday';

export default function BsBizTimeUpdate(props) {
	//props
	const isOpen = props.isOpen;
    const [ isHoliday,setHoliday ] = useState(true);
	//Promise-Callback
    //데이터로딩
    const { validated, setValidated } = useSignupHook();
    const [ isSubmit, setSubmit ] = useState(false);
    const parentsRef = useRef();
	return (
		<>
		<BottomSheet open={isOpen} onDismiss={(e) => props.onDismiss(false)}
			snapPoints={({ maxHeight }) => maxHeight}
			skipInitialTransition expandOnContentDrag
			header={
				<div className="TitleArea">
					<div></div><div className="Title">영업시간 수정하기</div><div></div>
					<button onClick={(e) => props.onDismiss(false)}
						className="btnBottomSheetClose" type='button'><FontAwesomeIcon icon={['fal', 'xmark']} /></button>
				</div>
			}
			footer={
				<div className="btnArea">
					<button onClick={(e) => !isSubmit ? parentsRef.current.sumbitHandler(isHoliday) : null }
						disabled={validated.key == null ? true : validated.key ? true : false}
						className="submitButton">{isSubmit ? <Spinner label={'잠시만 기다려주세요'} /> : '확인'}</button>
				</div>
			}>
            <Holiday isHoliday={isHoliday} setHoliday={setHoliday} />
            <Days bizDayListAPI={props.bizDayListAPI} onDismiss={props.onDismiss} ref={parentsRef} onValid={setValidated} />
		</BottomSheet>
		</>
	)
}