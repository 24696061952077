import React, { useCallback, useState } from 'react';

//style
import styles from 'src/asset/scss/style/mypage.module.scss';
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/select.scss';
//Store
import usePartnerStore from 'src/webapp/stores/partner.store';
//Hook
import useMyPageHook from 'src/webapp/hooks/mypage/mypage.hook';
//Comp
import Img from 'src/webapp/views/component/picture/Img';

export default function ProfileUpdate(props) {
    //props
    const headCntList = props.headCntList;
    //Store
    const { info } = usePartnerStore();
    //Hook
    const { updateRef2//파트너수정useRef
        , inputValidateRef2Handler//입력데이터검증
        , btnDisabled//버튼활성화여부useState
        , partnerUpdateAPI//회원정보수정API
    } = useMyPageHook();
    //Promise-Callback
    //데이터로딩
    const [isPending, setPending] = useState(false);
    const updateCallback = useCallback(() => {
        setPending(true);
        Promise.all([partnerUpdateAPI(2)]).then((e) => {
            props.onDismiss(false);
        });
    }, []);
    return (
        <>
            <div className={styles.infoUpdateContainer}>
                <div className={[styles.imgArea, "imgArea", "rounded"].join(" ")}>
                    <Img src={info.IMG_SRC} alt={info.PARTNER_NAME} />
                </div>
                <div className={styles.infoUpdateArea}>
                    {/* 버튼 */}
                    <div className={styles.btnArea}>
                        <button onClick={(e) => props.onDismiss(false)}>취소</button>
                        <button onClick={(e) => !isPending ? updateCallback() : null}
                            disabled={btnDisabled}>수정완료</button>
                    </div>
                    <div className={styles.inputField}>
                        <label>정비소명</label>
                        <div>
                            <input onChange={inputValidateRef2Handler}
                                ref={el => (updateRef2.current[0] = el)}
                                defaultValue={info.PARTNER_NAME}
                                type="text" inputMode="normal" placeholder="정비소명을 입력해주세요" />
                        </div>
                    </div>
                    <div className={styles.selectField}>
                        <label>정비인원</label>
                        <div className="select dark">
                            <SelectSearch ref={el => (updateRef2.current[1] = el)}
                                onChange={el => { inputValidateRef2Handler(); (updateRef2.current[1].value = el); }}
                                options={headCntList.current} defaultValue={info.MECHANIC} />
                        </div>
                    </div>
                    <div className={styles.inputField}>
                        <label>소개글</label>
                        <div>
                            <textarea onChange={inputValidateRef2Handler}
                                ref={el => (updateRef2.current[2] = el)}
                                defaultValue={info.SUMMARY} placeholder="소개글을 입력해주세요" maxLength={500}>
                            </textarea>
                        </div>
                        <em>최대 500자</em>
                    </div>
                </div>
            </div>
        </>
    );
}