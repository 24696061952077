import React, { useEffect, Suspense, lazy } from 'react';
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import useMyPageHook from 'src/webapp/hooks/mypage/mypage.hook';
//Comp
import _profileInfo from './ProfileInfo';//프로필정보
import _profileUpdate from './ProfileUpdate';//프로필수정
const _bsInfoUpdate	 = lazy(() => import('../../bottomsheet/BsInfoUpdate'), { ssr: false });

export default function Profile() {
    //Hook
    const { timeListAPI//타임리스트API
        , windowSizes//화면사이즈useRef
        , windowResizeHandler//화면사이즈핸들러
    } = useComnHook();
    const { headCntList//정비인원수useRef
        , setHeadCntHandler//정비인원수셋팅
        , isOpen//수정오픈useState
        , onOpenHandler//수정팝업on핸들러
        , handleDismissChange//수정팝업off핸들러
    } = useMyPageHook();
    //useEffect
    useEffect(() => {
        windowResizeHandler();//화면사이즈핸들러
        setHeadCntHandler();//정비인원수셋팅
        timeListAPI();//타임리스트API
	} ,[]);

    return (
        <>
        {!isOpen ?
            <>
            <_profileInfo onOpen={onOpenHandler} />{/* 프로필정보 */}
            </>
        :  isOpen && windowSizes.current.width > 767 ? 
            <>
            <_profileUpdate headCntList={headCntList} onDismiss={handleDismissChange} />{/* 프로필수정 */}
            </>
        : isOpen && windowSizes.current.width <= 767 ? 
            <>
            {/* bottomsheet */}
            <Suspense fallback={null}><_bsInfoUpdate headCntList={headCntList} isOpen={isOpen} onDismiss={handleDismissChange} /></Suspense>{/* 회원정보수정팝업 */}
            </>
        : null}
        </>
    );
}