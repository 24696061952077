import React, { useState, useCallback, useRef } from "react";
import axios from "axios";
//store
import usePartnerStore from "src/webapp/stores/partner.store";

const useVehicleRegHook = () => {
    /* ===== 공통 =====*/
    const isLock = useRef(false);
    const [ isOpen, setOpen ] = useState({init: false, brand: false, model: false, success: false});
    const { partner } = usePartnerStore();
    //팝업화면전환
    const changeOpenHandler = (name) => {
        let temp = {};
        for(let prop in isOpen) {
            let value = false;
            if(prop == name) { value = true; }
            temp[prop] = value;
        }
        setOpen(temp);
    }
    //팝업off핸들러
    const onDismissHandler = (name) => { changeOpenHandler(name); }
    /* ===== 공통 =====*/

    /* ===== 브랜드선택 ===== */
    //브랜드선택
    const [ brand, setBrand ] = useState('');
    const setBrandHandler = (item) => { setBrand(item); }
    const [ brandItem, setBrandItem ] = useState({});
    const handleBrandChange = (data) => { setBrandItem(data); }
    const [ brandList, setBrandList ] = useState([]);
    const pageRef = useRef(20);//무한로딩Ref
    //브랜드리스트API
    const brandListAPI = async (type, isDelete) => {//type - 0.최초로딩, 1.무한로딩
        const DATA = { DEL_YN:isDelete ? "" : "N", ROWNUM:type === 0 ? 20 : pageRef.current };
        console.log(DATA);
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}brandMasterListForJson.do`, DATA)
                .catch(err => { console.log("Error >>", err); reject(err); })
                .then(response => {
                    const res = response.data;
                    if(res.message != "SUCCESS") { pageRef.current = null; return; }
                    if(res.message == "SUCCESS") { 
                        if(type === 0){pageRef.current = 20;}
                        const responseData = pageRef.current === 20 ? res.data : brandList.concat(res.data);
                        setBrandList(responseData);
                        pageRef.current = (res.data.length >= 20 ? pageRef.current + 20 : null);
                    }
                    resolve(true);
                });
        })
    }

    /* ===== 브랜드선택 ===== */

    /* ===== 모델선택 ===== */
    //브랜드모델리스트API
    const [ modelList, setModelList ] = useState([{MODEL_KOR_NAME: "전체선택", value:1}]);
    const modelListAPI = async (type, brandCd, isDelete) => {//type - 0:최초로딩, 1:무한로딩 2. 리스트전체로딩
        const DATA = { BRAND_CD:brandCd, DEL_YN:isDelete ? "" : "N", ROWNUM:type === 0 ? 20 : type === 1 ? pageRef.current : 0 };
        console.log(DATA);
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}brandItemListForJson.do`, DATA)
            .catch(err => { console.log("Error >>", err); reject(err); })
            .then(response => {
                const res = response.data;
                console.log(res);
                if(res.message != "SUCCESS") { setModelList([]); }
                if(res.message == "SUCCESS") { 
                        if(checkedList[0] && checkedList[0].value == 1) {
                            setCheckedList(checkedList.concat(res.data));
                        }
                        setModelList(modelList.concat(res.data));
                    }
					resolve(true); 
				});
		})
    }

    //체크박스제어
    const [ checkedList, setCheckedList ] = useState([]);
    const setModelHandler = useCallback((checked, item, model) => {
        if (item.MODEL_KOR_NAME == "전체선택"){
            if(checked){
                setCheckedList(model)
            }else{
                setCheckedList([]);
            }
            return;
        }
        if(checked) { setCheckedList([...checkedList, item]); } 
        else { setCheckedList(checkedList.filter((el) => el != item && el.value != 1));} 
    }, [checkedList]);
    /* ===== 모델선택 ===== */

    /* ===== 차량등록 ===== */
    //차량등록API
    const vehicleRegAPI = async (data) => {
        if(isLock.current) { return; }
        isLock.current = true;
        return new Promise((resolve, reject) => {
            let DATA = [];
            for (let i = 0; data.length > i; i++) {
                DATA.push({
                    "MODEL_CD":data[i].MODEL_CD, "BRAND_CD":data[i].BRAND_CD, "PARTNER_CD":partner.COMN_CD
                })
            }
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerVehicleRegForPartner.do`, DATA)
                    .catch(err => { console.log("Error >>", err); reject(err); })
                    .then(response => {
                        const res = response.data;
                        if(res.message == "SUCCESS") { }
                        resolve(true);
                    });
            setTimeout(function() { isLock.current = false; }, 3000);
        })
    }
    /* ===== 차량등록 ===== */

    return { isOpen, changeOpenHandler, onDismissHandler//공통
        , pageRef, brandList, brandListAPI, brand, setBrandHandler, brandItem, handleBrandChange//브랜드선택
        , modelList, modelListAPI, checkedList, setCheckedList, setModelHandler//모델선택
        , vehicleRegAPI //취급차량등록
    };
}

export default useVehicleRegHook;