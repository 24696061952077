import React, { useEffect, Suspense } from 'react';
//style
import styles from 'src/asset/scss/style/userComn.module.scss'
//Hook
import useSignupHook from 'src/webapp/hooks/user/signup.hook';
//Comp
import _titleArea from '../_titleArea';

export default function AuthPassWd() {
	//Hook
	const { checkedStorage//스토리지유무확인
		, validatedPasswdHandler //비밀번호유효성검사
		, onhandleOnKeyPress//엔터이벤트
		, pageOnLoadHandler //버튼클릭
		, validated //유효성확인객체useState
		, signupRef//회원가입Ref
	} = useSignupHook();

	//useEffect
	useEffect(() => {
		checkedStorage();//스토리지유무확인
		signupRef.current[0].focus();
	}, []);

	return (
		<>
			<section className={["container", styles.userContainer].join(" ")}>
				<div className={styles.wrapper}>
					{/* 타이틀 */}
					<Suspense fallback={null}><_titleArea usePrev={true} title={`비밀번호 입력하기`} subtitle={`영문,숫자,특수문자 조합/8자 이상`} /></Suspense>
					<div className={["flickPanel", styles.inputArea].join(" ")}>
						{/* 입력필드 */}
						<div className={styles.inputField}><label>비밀번호</label>
							<div className={validated.key == null ? null : validated.key ? "invalidate" : "validate"}>
								<input onChange={(e) => validatedPasswdHandler(e)}
									name="passwd" type="password" inputMode="normal"
									ref={el => (signupRef.current[0] = el)}
									placeholder="비밀번호 입력" />
								<label className="validateMsg">{validated.msg}</label>
							</div>
						</div>
						<div className={styles.inputField}><label>비밀번호 확인</label>
							<div className={validated.key == null ? null : validated.key ? "invalidate" : "validate"}>
								<input onChange={(e) => validatedPasswdHandler(e)}
									onKeyDown={(e) => validated.key ? null : onhandleOnKeyPress(e, 2)}
									ref={el => (signupRef.current[1] = el)}
									name="passwdChk" type="password" inputMode="normal"
									placeholder="비밀번호 확인" />
							</div>
						</div>
					</div>

					{/* 버튼 */}
					<div className={["fixedPanel", styles.btnArea].join(" ")}>
						<button onClick={() => pageOnLoadHandler(2)}
							disabled={validated.key == null ? true : validated.key ? true : false}
							className="submitButton">다음</button>
					</div>
				</div>
			</section>
		</>
	);
}