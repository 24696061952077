import React, { useEffect, useState } from 'react';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/style/mypage.module.scss'
//hook
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
import useMyPageHook from 'src/webapp/hooks/mypage/mypage.hook';
//store
import usePartnerStore from 'src/webapp/stores/partner.store';
//comp
import BizTimeUpdate from 'src/webapp/views/user/mypage/bottomsheet/BsBizTimeUpdate';

export default function BizTimeList() {
    const { axiosPostHandler } = useAxiosHook();
    const { partner, isState } = usePartnerStore();

    const {
        isOpen//수정오픈useState
        , handleDismissChange//수정팝업off핸들러
    } = useMyPageHook();
    /* =============== 영업시간리스트 =============== */
    const [bizDayList, setBizDayList] = useState([]);
    const bizDayListAPI = async () => {
        // const DATA = { PARTNER_CD: partner.COMN_CD };
        const DATA = { PARTNER_CD: partner.COMN_CD };
        const result = await axiosPostHandler(`partnerBizDayListForPartner.do`, DATA);
        console.log(result)
        if (!result) { return; }
        setBizDayList(result.data);
    }
    useEffect(() => {
        if(isState) {
            bizDayListAPI();
        }
    }, [isState])
    /* =============== 영업시간리스트 =============== */

    return (
        <>
            <div className={[styles.comnContainer, styles.bizDateContainer].join(" ")}>
                <div className="subtitle">
                    <label>운영시간</label>
                    <button onClick={(e) => handleDismissChange(true)}><FontAwesomeIcon icon={['fal', 'pen-to-square']} />수정하기</button>{/* 수정하기버튼 */}
                </div>
                <div className={styles.bizDateArea}>
                    {bizDayList.map((item) => (
                        <div className={item.DAYS === item.TODAY ? styles.selected : null}>
                            <label>{item.DAYS_STR}</label>
                            <div>
                                <label>
                                    {item.BIZ_TIME}
                                    {item.BREAK_TIME !== undefined && item.BREAK_TIME !== null ?
                                        <><br />{item.BREAK_TIME}</>
                                        : null}
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {isOpen ? <BizTimeUpdate isOpen={isOpen} bizDayListAPI={bizDayListAPI} onDismiss={handleDismissChange} /> : null}
        </>
    );
}