import React, { useEffect, Suspense, lazy, useRef } from 'react';

//style
import styles from 'src/asset/scss/style/userComn.module.scss'
//comp
import _emailForm from 'src/webapp/views/user/find/email/_emailForm';
//Store
import usePartnerStore from 'src/webapp/stores/partner.store';
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import useFindHook from 'src/webapp/hooks/user/find.hook';
import useSignupHook from 'src/webapp/hooks/user/signup.hook';

const lazyWithPreload = importFunction => {
	const Component = lazy(importFunction);
	Component.preload = importFunction;

	return Component;
};
const _titleArea = lazyWithPreload(() => import('../_titleArea'));

export default function Pw() {

	//ref
	const ref = useRef();

	//Store
	const { pwData } = usePartnerStore();
	//Hooks
	const { compPreload } = useComnHook();//preload
	const { emailForm//이메일폼
		, signupEmailCertSendAPI //버튼클릭(이메일전송)
	} = useSignupHook();
	const { onFormChangeHandler//폼저장핸들러
		, pwForm //비밀번호찾기입력폼State	
		, validated//유효성확인useState
		, onEnterHandler//엔터키작동핸들러
		, onSubmitHandler//버튼클릭작동핸들러
		, findPwRef
	} = useFindHook();

	//useEffect
	useEffect(() => {
		const comp = [_titleArea];
		compPreload(comp);//preload
		findPwRef.current[0].focus();
	}, []);

	useEffect(() => {
		if (pwData.PARTNER_CD != undefined || pwData.PARTNER_CD != null) {
			signupEmailCertSendAPI(3, findPwRef.current[1].value);//인증이메일전송API
		}
	}, [pwData]);

	return (
		<>
			<section className={["container", styles.userContainer].join(" ")}>
				<div className={styles.wrapper}>
					{/* 타이틀 */}
					<Suspense fallback={null}><_titleArea title={`비밀번호 찾기`} subtitle={pwForm.msg} /></Suspense>

					{/* 입력필드 */}
					<div className={["flickPanel", styles.inputArea].join(" ")}>
						<div className={styles.inputField}><label>아이디</label>
							<div>
								<input onChange={(e) => onFormChangeHandler(e, 2)}
									ref={el => (findPwRef.current[0] = el)}
									name="partnerId" type="text" inputMode="normal"
									placeholder="비밀번호 입력" />
							</div>
						</div>
						<div className={styles.inputField}><label>이메일</label>
							<div>
								<input onChange={(e) => onFormChangeHandler(e, 2)}
									onKeyDown={(e) => onEnterHandler(e, 2)}
									ref={el => (findPwRef.current[1] = el)}
									name="email" type="text" inputMode="email"
									placeholder="비밀번호 확인" />
							</div>
						</div>
					</div>

					{/* 버튼 */}
					<div className={["fixedPanel", styles.loginBtnArea].join(" ")}>
						<button onClick={(e) => onSubmitHandler(2)}
							disabled={validated.key ? false : true}
							className="submitButton">인증번호 보내기</button>
					</div>
				</div>
			</section>
			{/* 이메일 폼 */}
			{<div className="emailForm" ref={emailForm}><_emailForm /></div>}
		</>
	);
}