import React, { useRef, useState } from "react";
//Store
import useComnStore from 'src/webapp/stores/comn.store';
import usePartnerStore from 'src/webapp/stores/partner.store';
//sound
import reserveSuccess from 'src/asset/sounds/reserveSuccess.mp3';

const useSocketHook = () => {
    //Store
    const { setSocket, setSound, setPlaying } = useComnStore();
    const { partner } = usePartnerStore();
    //소켓연결
    const ws = useRef(null);
    const soundButton = useRef([]);
    //효과음
    //예약완료효과음
    const reserveSuccessSound = new Audio(reserveSuccess);
    //예약변경효과음
    const reserveUpdateSound = new Audio(reserveSuccess);
    //예약완료버튼클릭트리거
    const reserveSuccessButtonTrigger = useRef(null);
    //예약변경버튼클릭트리거
    const reserveUpdateButtonTrigger = useRef(null);
    const socketConnect = () => {
        if (ws.current != null || Object.keys(partner).length < 0) { return; }
        const SOCKET_URL = `${process.env.REACT_APP_SOCKET_URL}`;
        const initData = { "state": "ENTER", "sender": partner.COMN_CD, "type": "PARTNER" };
        ws.current = new WebSocket(SOCKET_URL);
        ws.current.onopen = () => {
            console.log("connected to " + SOCKET_URL, initData);
            ws.current.send(JSON.stringify(initData));
        };
        ws.current.onclose = (error) => {
            console.log("disconnect from " + SOCKET_URL, error);
            ws.current = null;
            socketConnect();
        };
        ws.current.onerror = (error) => {
            console.log("connection error " + SOCKET_URL, error);
            ws.current = null;
        };
        ws.current.onmessage = (e) => {
            const data = JSON.parse(e.data);
            if (data.KEY == "PARTNER_RESERV_SUCCESS") {
                reserveSuccessButtonTrigger.current?.click();
            }
            if (data.KEY == "PARTNER_RESERV_UPDATE") {
                reserveUpdateButtonTrigger.current?.click();
            }
            const math = Math.random().toString(36).substr(2, 11);
            setSocket(math);
        };
    }

    return { socketConnect, ws, soundButton, reserveSuccessButtonTrigger, reserveUpdateButtonTrigger, reserveSuccessSound, reserveUpdateSound };
}

export default useSocketHook;