import React, { useEffect, Suspense } from 'react';
//comp
import _emailForm from '../email/_emailForm';
//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//Hook
import useSignupHook from 'src/webapp/hooks/user/signup.hook';
//Comp
import _titleArea from '../../_titleArea';

export default function Verify() {
    //Hook
    const { checkedStorage //스토리지유무확인
        , verifyInputChangeHandler //인증번호입력
        , onhandleOnKeyPress//엔터이벤트
        , verifyCertCheckAPI //버튼클릭(인증번호확인)
        , signupCertReSendHandler//인증번호재전송
        , verifyMap//인증번호배열
        , validated//유효성확인객체useState
        , verifyRef//인증번호입력객체useRef
        , emailForm//이메일폼
        , verifyCnt//인증번호발송횟수useState
    } = useSignupHook();
    //useEffect
    useEffect(() => {
        checkedStorage();//쿠키유무확인
        verifyRef.current[0].focus();
    }, []);

    return (
        <>
            <section className={["container", styles.userContainer].join(" ")}>
                <div className={styles.wrapper}>
                    {/* 타이틀 */}
                    <_titleArea
                        usePrev={true} title={`인증번호 입력`}
                        subtitle={verifyCnt >= 5 ? `횟수초과, 처음부터 다시 진행해주세요(` + verifyCnt + `/5)` : `받으신 인증번호를 정확히 입력해주세요(` + verifyCnt + `/5)`} />

                    {/* 입력필드 */}
                    <div className={["flickPanel", styles.verifyArea].join(" ")}>
                        {verifyMap.map((data, idx) => (
                            <div key={idx}>
                                <input onChange={(e) => {
                                        verifyInputChangeHandler(e, idx)
                                        //현재 여기 transition걸면 인풋이 제대로 안받아짐
                                }}
                                    onKeyDown={(e) => idx == 4 && validated.key ? null : onhandleOnKeyPress(e, 5, 1)}
                                    name={`verify` + idx} ref={el => (verifyRef.current[idx] = el)}
                                    type="text" inputMode="numeric"
                                    maxLength={1}
                                    placeholder={0} />
                            </div>
                        ))}
                    </div>

                    {/* 버튼 */}
                    <div className={["fixedPanel", styles.btnArea].join(" ")}>
                        <button onClick={() => verifyCertCheckAPI(1)}
                            disabled={validated.key == null ? true : validated.key ? true : false}
                            className="submitButton">확인</button>
                        <button onClick={(e) => signupCertReSendHandler(e)}
                            className="submitSecButton">다시 보내기</button>
                    </div>
                </div>
            </section>
            {/* 이메일 폼 */}
            {<div style={{ display: "none" }} ref={emailForm}><_emailForm /></div>}
        </>
    );
}