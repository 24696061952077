import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
//style
import styles from 'src/asset/scss/style/userSignup.module.scss';
//comp
import Day from './Day';//영업시간 및 휴게시간 설정
import useSignupHook from 'src/webapp/hooks/user/signup.hook';

const Days = forwardRef((props, parentsRef) => {
    useImperativeHandle(parentsRef, () => ({//상위컴포넌트에서 호출
        sumbitHandler(item) {
            holidayRef.current = item;
            pageOnLoadHandler(8)
        }
    }));
    const { bizDate, //영업타입, 영업요일리스트Stat
        holidayRef, //
        onChangeHandler, //영업타입 및 요일 변경핸들러
        dateFilter, //요일출력필터
        pageOnLoadHandler, //페이지이동핸들러
        bizTimeValidationHandler, //영업시간검증핸들러
        validated,//검증state
        dayStartBizRef, dayEndBizRef, breakTimeStartBizRef, breakTimeEndBizRef
    } = useSignupHook();

    useEffect(() => {
        props.onValid(validated)
    }, [validated])
        useEffect(() => {
            if (bizDate.list.length > 0){
                bizTimeValidationHandler(null, "event", null);
            }
        }, [bizDate.list])
    return (
        <div className={styles.daysArea}>
            <h5>영업시간을 알려주세요.</h5>
            <div className={[styles.btnArea, styles.daysBtnArea].join(" ")}>
                <button className={bizDate.type === 1 ? styles.selected : null} onClick={(e) => { onChangeHandler(null, null, 1);}}>모든 영업일이 같아요</button>
                <button className={bizDate.type === 2 ? styles.selected : null} onClick={(e) => { onChangeHandler(null, null, 2);}}>평일/주말 달라요</button>
                <button className={bizDate.type === 3 ? styles.selected : null} onClick={(e) => { onChangeHandler(null, null, 3);}}>요일별로 달라요</button>
            </div>
            <div className={styles.daysContainer}>
                {bizDate.list !== null && bizDate.list.map((v, idx) => (
                    <div key={idx}>
                        {bizDate.type === 3 ?
                            <div className={styles.dateListArea}>
                                {[...Array(7)].map((x, i) => <button className={bizDate.list[idx].includes(i + 1) ? styles.selected : null} onClick={(e) => onChangeHandler(idx, i, 5)}>{dateFilter(i)}</button>)}
                            </div>
                            : null}
                        <Day type={bizDate.type} index={idx} length={bizDate.list.length} ref={{ dayStartBizRef, dayEndBizRef, breakTimeStartBizRef, breakTimeEndBizRef }} onValid={bizTimeValidationHandler} />
                        {idx === 0 && bizDate.type === 2 && bizDate.list.length < 3 ? <button className={styles.btnDayAdd} onClick={(e) => { onChangeHandler(null, null, 4);}}>토,일 분리</button> : null}
                        {bizDate.type === 3 && (bizDate.type >= (bizDate.list.length - 1)) ? <button onClick={(e) => { onChangeHandler(idx + 1, null, 5);}} className={styles.btnDayAdd}>추가</button> : null}
                    </div>
                ))}
            </div>
        </div>
    )
})
export default Days;