import React from 'react';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//style
import styles from 'src/asset/scss/style/mypage.module.scss';
//Store
import usePartnerStore from 'src/webapp/stores/partner.store';
//Comp
import Img from 'src/webapp/views/component/picture/Img';

export default function ProfileInfo(props) {
    //Store
    const { info } = usePartnerStore();
    return (
        <>
        {/* 프로필정보 */}
        <div className={styles.infoContainer}>
            <button onClick={(e) => props.onOpen()}><FontAwesomeIcon icon={['fal', 'pen-to-square']} />수정하기</button>{/* 수정하기버튼 */}

            <div className={styles.infoArea}>{/* 프로필정보 */}
                <div className={[styles.imgArea, "imgArea", "rounded"].join(" ")}>
                    <Img src={info.IMG_SRC} alt={info.PARTNER_NAME} width={240} height={240} />
                </div>
                <div>
                    <em onClick={() => navigator.clipboard.writeText(info.RECOMMEND_ID)}>추천코드 - {info.RECOMMEND_ID}</em>
                    <div>
                        <label>
                            <em>{info.PARTNER_NAME}</em>
                            <button onClick={(e) => props.onOpen()}><FontAwesomeIcon icon={['fal', 'pen-to-square']} /></button>{/* 수정하기버튼 */}
                        </label>
                    </div>
                    <pre>{info.SUMMARY}</pre>
                    <div className={styles.basicInfo}>
                        <b>정비인원 {info.MECHANIC}명</b>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}