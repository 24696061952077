import React, { lazy } from 'react';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//style
import styles from 'src/asset/scss/style/item.module.scss'
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/subFilter.scss';
//Hook
import useItemHook from 'src/webapp/hooks/item/item.hook';
//Comp
import NavBar from 'src/webapp/views/component/NavBar';
import TitleBar from 'src/webapp/views/component/TitleBar';
const _itemList = lazy(() => import('./comp/ItemList'), { ssr: false });//아이템목록

export default function Items() {
    //Hook
    const { excelExportHandler//엑셀다운로드
        , optionList//셀렉트옵션리스트
        , option//선택된useState
        , setOption//선택useState
    } = useItemHook();

    return (
        <>
        <section className="fixedPanel container main">
            <NavBar id={4} />{/* 메뉴바 */}
            <div className="layer">
                <div className="fixedPanel">
                    <TitleBar title={`가격표 관리`} />{/* 타이틀 */}
                    {/* 검색창 */}
                    <div className="searchArea">
                        <div>
                            {/* 엑셀다운로드 */}
                            <button onClick={(e) => excelExportHandler()}
                                className="btnExcel"><FontAwesomeIcon icon={['fal', 'arrow-down-to-line']} /></button>
                        </div>
                        <div>
                            <div className={["subFilter", styles.subFilter].join(" ")}>
                                <SelectSearch onChange={(e) => { setOption(e) }}
                                    options={optionList} name={optionList.name} value={option} />
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flickPanel">
                    <_itemList option={option} />
                </div>
            </div>
        </section>
        </>
    );
}